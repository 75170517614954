import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Menubar from "../../admin/navbar/AdminMenubar";
import logo from "../../rbz-logo.png";

const Sidebar = () => {
  const menuItemlocal = Menubar();
  const [isToggled, setIsToggled] = useState(false);
  const menuItem = JSON.parse(menuItemlocal.menuItemlocal);

  useEffect(() => {
    const MenuLoadAuto = () => {
      const x = 100;
      const y = 100;
      const event = new MouseEvent("mousemove", {
        clientX: x,
        clientY: y,
      });
      // Dispatch the event
      document.dispatchEvent(event);
    };
    const timer = setTimeout(MenuLoadAuto, 1000);
    return () => clearTimeout(timer);
  }, []);

  const currentUrl = window.location.href;
  const urlParts = currentUrl.split("/");
  const lastUrlPart = urlParts[urlParts.length - 1];

  const handleToggle = () => {
    setIsToggled((prevState) => !prevState);
    if (!isToggled) {
      document.body.classList.add("toggle-sidebar");
    } else {
      document.body.classList.remove("toggle-sidebar");
    }
  };

  const saveheadername = (id, name) => {
    document.body.classList.remove("toggle-sidebar");
    sessionStorage.setItem("menuname", id);
    sessionStorage.setItem("submenuname", name);
  };

  return (
    <aside id="sidebar" className="sidebar admin-sidebar">
      <div className="d-flex align-items-center justify-content-between sidebar-header ">
        <Link to="/dashboard" className="logo d-flex align-items-center">
          <img src={logo} alt="" />
          <span className="no-collapp">Enhanced Document Management System (eDMS)</span>
        </Link>
        {isToggled === false ? (
          <div
            className="toggle-sidebar-btn toggle-extra-side-lg "
            onClick={handleToggle}
          >
            <i className="bi bi-rewind-fill "></i>{" "}
          </div>
        ) : (
          <div
            className="toggle-sidebar-btn toggle-extra-side-lg "
            onClick={handleToggle}
          >
            <i className="bi bi-fast-forward-fill"></i>
          </div>
        )}
      </div>
      <ul className="sidebar-nav" id="sidebar-nav">
        {/* <li className="nav-item activemenu align-end"> 
          {isToggled === false ? <i className="bi bi-chevron-double-left toggle-sidebar-btn" onClick={handleToggle}></i> : <i className="bi bi-chevron-double-right toggle-sidebar-btn" onClick={handleToggle}></i> }
        </li> */}

        {menuItem?.map((items, index) => {
          return (
            <>
              <li className="nav-item" key={index}>
                {items.menuName === "Application Rollback" ? (
                  <Link
                    className="nav-link"
                    data-bs-target={"#menu" + items.id}
                    to={"/" + items.menuURL}
                    title={items.menuName}
                    onClick={() =>
                      saveheadername(items.menuName, "Application Rollback")
                    }
                  >
                    <i
                      className="bi bi-arrow-up-right-square-fill"
                      style={{ color: "#fff" }}
                    ></i>
                    <span>{items.menuName}</span>{" "}
                  </Link>
                ) : (
                  <Link className="nav-link " title={items.menuName}>
                    <i className="bi bi-arrow-up-right-square-fill"></i>
                    <span>{items.menuName}</span>
                  </Link>
                  // <Link
                  //   className="nav-link collapsed"
                  //   data-bs-target={"#menu" + items.id}
                  //   data-bs-toggle="collapse"
                  //   to="#"
                  //   title={items.menuName}
                  //   style={{
                  //     background: "#42a5f5",
                  //     color: "#fff",
                  //     margin: "1px 0px",
                  //   }}
                  //   onClick={() => menuindexHanlde(index)}
                  // >
                  //   <i
                  //     className="bi bi-arrow-up-right-square-fill"
                  //     style={{ color: "#fff" }}
                  //   ></i>
                  //   <span>{items.menuName}</span>{" "}
                  //   <i
                  //     className="bi bi-chevron-down ms-auto"
                  //     style={{ color: "#fff" }}
                  //   ></i>
                  // </Link>
                )}

                <ul className="nav-content  ">
                  {items?.subMenu?.map((submenuitem, subindex) => {
                    return (
                      <li
                        key={subindex}
                        className={
                          lastUrlPart == submenuitem.subMenuURL
                            ? "activemenu"
                            : ""
                        }
                      >
                        <Link
                          to={"/" + submenuitem.subMenuURL}
                          title={submenuitem.subMenuName}
                          onClick={() =>
                            saveheadername(
                              items.menuName,
                              submenuitem.subMenuName
                            )
                          }
                        >
                          <i className={"bi " + submenuitem.subMenuIcon}></i>
                          <span>{submenuitem.subMenuName}</span>
                        </Link>
                      </li>
                    );
                  })}
                </ul>
              </li>
            </>
          );
        })}
      </ul>
    </aside>
  );
};

export default Sidebar;
