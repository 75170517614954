import React, { useEffect, useState, useRef } from "react";
import { Link, useNavigate } from "react-router-dom";
import logo from "../Logo_T.png";
import { APIURL } from "../constant";
import axios from "axios";
import "react-toastify/dist/ReactToastify.css";
import { toast } from "react-toastify";
import { Helmet } from "react-helmet";
import background from "../login/img/registration_2.jpg";
import { Buffer } from 'buffer';
import ErrorsLogApi from "../components/ErrorslogApi";


const BankRegister = () => {
  const { ErrorlogData } = ErrorsLogApi();
  const navigation = useNavigate();
  const nameRef = useRef(null);
  const emailRef = useRef(null);
  const phoneNumberRef = useRef(null);
  const banknameRef = useRef(null);
  const banktypeRef = useRef(null);
  const addressRef = useRef(null);
  const usernameRef = useRef(null);
  const passwordRef = useRef(null);

  const [toastDisplayed, setToastDisplayed] = useState(false);

  const [masterBank, setmasterBank] = useState([]);
  const [bankData, setbankdata] = useState({
    name: "",
    emailID: "",
    phoneNumber: "",
    address: "",
    userName: "",
    password: "",
    BankID: "",
    Applyingfor: "",
  });
  const [errors, setErrors] = useState({});
  const emailRegex = /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Z|a-z]{2,}$/;

  const getHandledata = async () => {
    const DataReferred = {}
    try {
      const response = await axios.post(APIURL + "Master/GetMasterBank");
      if (response.data.responseCode == "200") {
        setTimeout(() => {
          setmasterBank(response.data.responseData);
        }, 1000);
      } else {
        setmasterBank([]);
      }
    } catch (err) {
      const api = "Master/GetMasterBank"
      ErrorlogData(DataReferred, err, api)
      console.log(err);
    }
  };

  useEffect(() => {
    document.addEventListener('contextmenu', (event) => {
      event.preventDefault();
    });

    return () => {
      document.removeEventListener('contextmenu', (event) => {
        event.preventDefault();
      });
    };
  }, []);

  useEffect(() => {
    const handleKeyDown = (e) => {
      // Prevent default behavior for specific key combinations
      if (e.ctrlKey && e.shiftKey && e.key === 'I') {
        e.preventDefault();
        console.log('Ctrl + Shift + I is disabled');
      }

      if (e.ctrlKey && e.keyCode === 85) {
        e.preventDefault();
        console.log('Ctrl + U is disabled');
      }

      // if (e.ctrlKey && (e.keyCode === 67 || e.keyCode === 86 || e.keyCode === 85 || e.keyCode === 117)) {
      //   e.preventDefault();
      //   console.log('Ctrl + C, Ctrl + V, Ctrl + U, and Ctrl + F6 are disabled');
      // }
    };

    const handleKeyPress = (e) => {
      // Prevent default behavior for Ctrl + U
      if (e.ctrlKey && e.key === 'u') {
        e.preventDefault();
        console.log('Ctrl + U is disabled');
      }
    };

    // Attach event listeners
    document.addEventListener('keydown', handleKeyDown);
    document.addEventListener('keypress', handleKeyPress);

    // Cleanup event listeners on component unmount
    return () => {
      document.removeEventListener('keydown', handleKeyDown);
      document.removeEventListener('keypress', handleKeyPress);
    };
  }, []);

  // form validation check
  const validateForm = () => {
    let valid = true;
    const newErrors = {};

    const specialCharacterRegex = /[!@#$%^&*(),.?":{}|<>]/;
    const numericRegex = /\d/;

    if (bankData?.name === "") {
      newErrors.name = "Full name is required";
      valid = false;
    }
    if (bankData?.emailID === "") {
      newErrors.emailID = "Email is required";
      valid = false;
      // setemailerror(true);
    } else if (!emailRegex.test(bankData?.emailID)) {
      newErrors.emailID = "Please enter valid email Id";
      valid = false;
      //   setemailerror(true);
    }
    if (bankData?.Applyingfor === "") {
      newErrors.Applyingfor = "Bank type required";
      valid = false;
    }
    if (bankData?.BankID === "") {
      newErrors.BankID = "Bank name is required";
      valid = false;
    }
    if (bankData?.phoneNumber === "") {
      newErrors.phoneNumber = "Contact number is required";
      valid = false;
    } else if (bankData?.phoneNumber.length !== 10) {
      newErrors.phoneNumber = "Contact number should be 10 digits only";
      valid = false;
    }

    if (bankData?.address === "") {
      newErrors.address = "Address is required";
      valid = false;
    }
    if (bankData?.userName === "") {
      newErrors.userName = "Username is required";
      valid = false;
    }
    if (bankData?.password === "") {
      newErrors.password = "Password is required";
      valid = false;
    } else if (bankData?.password.length < 8) {
      newErrors.password = "Password must be at least 8 characters long";
      valid = false;
    } else if (!specialCharacterRegex.test(bankData?.password)) {
      newErrors.password =
        "Password must contain at least one special character";
      valid = false;
    } else if (!numericRegex.test(bankData?.password)) {
      newErrors.password = "Password must contain at least one numeric digit";
      valid = false;
    }

    setErrors(newErrors);
    return valid;
  };

  const bankdataChangehandle = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    const specialChars = /[!@#$%^&*(),.?":{}|<>]/;
    let newErrors = {};
    let valid = true
    if (name == "name" && value.charAt(0) === ' ') {

      newErrors.name = "First character cannot be a blank space";
      valid = false
    }
    else if (name == "name" && (specialChars.test(value) ||
      value?.includes("_") ||
      value?.includes("+") ||
      value?.includes("=") ||
      value?.includes("'") ||
      value?.includes(";") ||
      value?.includes("[") ||
      value?.includes("]") ||
      value?.includes("]") || /[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/.test(value.charAt(0)))) {
      newErrors.name = "Special characters not allowed";
      valid = false
    }
    else if (name === "phoneNumber" && (value.length > 10)) {
      newErrors.phoneNumber = "Mobile numbers should be only 10 digits";
      valid = false
    }
    else if (name == "TINNumber" && value.charAt(0) === ' ') {

      newErrors.TINNumber = "First character cannot be a blank space";
      valid = false
    }
    else if (name == "TINNumber" && (specialChars.test(value) ||
      value?.includes("_") ||
      value?.includes("+") ||
      value?.includes("=") ||
      value?.includes("'") ||
      value?.includes(";") ||
      value?.includes("[") ||
      value?.includes("]") ||
      value?.includes("]") || /[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/.test(value.charAt(0)))) {
      newErrors.TINNumber = "Special characters not allowed";
      valid = false
    }
    else if (name == "emailID" && value.charAt(0) === ' ') {

      newErrors.emailID = "First character cannot be a blank space";
      valid = false
    }
    else if (name == "emailID" && /[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/.test(value.charAt(0))) {
      newErrors.emailID = "Special characters are not allowed as the first character";
      valid = false
    } else if (name == "emailID" && (value.includes("$") ||
      value.includes("`") ||
      value.includes("|") ||
      value.includes(" ") ||
      value.includes("~") ||
      value.includes(":") ||
      value.includes(",") ||
      value.includes(">") ||
      value.includes("<") ||
      value.includes("(") ||
      value.includes(")") ||
      value.includes("*") ||
      value.includes("&") ||
      value.includes("%") ||
      value.includes("#") ||
      value.includes("+") ||
      value.includes("?") ||
      value.includes("!") ||
      value.includes(";") ||
      value.includes("=") ||
      value.includes('"') ||
      value.includes(`'`) ||
      value.includes("/") ||
      value.includes("}") ||
      value.includes("{") ||
      value.includes("^") ||
      value.includes("\\") ||
      value.includes("]") ||
      value.includes("["))) {
      newErrors.emailID = "Not allowed as the character";
      valid = false
    }

    else if (name == "address" && value.charAt(0) === ' ') {

      newErrors.address = "First character cannot be a blank space";
      valid = false
    }
    else if (name == "address" && (specialChars.test(value) ||
      value?.includes("_") ||
      value?.includes("+") ||
      value?.includes("=") ||
      value?.includes("'") ||
      value?.includes(";") ||
      value?.includes("[") ||
      value?.includes("]") ||
      value?.includes("/") ||
      value?.includes("]"))) {
      newErrors.address = "Special characters not allowed";
      valid = false
    }
    else if (name === "userName" && value.charAt(0) === ' ') {
      newErrors.userName = "First character cannot be a blank space";
      valid = false
    }

    else if (name === "userName" && /[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/.test(value.charAt(0))) {
      newErrors.userName = "Special characters not allowed";
      valid = false
    }
    else if (name == "userName" && (value.includes("$") ||
      value.includes("`") ||
      value.includes("|") ||
      value.includes(" ") ||
      value.includes("~") ||
      value.includes(":") ||
      value.includes(",") ||
      value.includes(">") ||
      value.includes("<") ||
      value.includes("(") ||
      value.includes(")") ||
      value.includes("*") ||
      value.includes("&") ||
      value.includes("%") ||
      value.includes("#") ||
      value.includes("+") ||
      value.includes("?") ||
      value.includes("!") ||
      value.includes(";") ||
      value.includes("=") ||
      value.includes('"') ||
      value.includes(`'`) ||
      value.includes("/") ||
      value.includes("}") ||
      value.includes("{") ||
      value.includes("^") ||
      value.includes("\\") ||
      value.includes("]") ||
      value.includes("["))) {
      newErrors.userName = "Not allowed as the character";
      valid = false
    }
    else if (name === "password" && value.charAt(0) === ' ') {
      newErrors.password = "First character cannot be a blank space";
      valid = false
    }
    else {
      setErrors({});
      setbankdata((prevState) => ({
        ...prevState,
        [name]: value,
      }));

    }

    setErrors(newErrors);

    // if (emailRegex.test(bankData?.emailID.trim())) {
    //   setemailerror(false);
    // }
  };


  const bankApplyforhamdle = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setbankdata((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };


  // User/UserRegistration
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validateForm()) {
      const DataReferred = {
        Applyingfor: bankData.Applyingfor,
        BankID: bankData.BankID,
        address: bankData.address,
        emailID: bankData.emailID,
        name: bankData.name,
        password: new Buffer(bankData?.password).toString('base64'),
        phoneNumber: bankData.phoneNumber,
        userName: bankData?.userName
      }
      await axios
        .post(APIURL + "User/UserRegistration", DataReferred)
        .then((response) => {
          if (response.data.responseCode == "200") {
            toast.success(response.data.responseMessage);
            setbankdata({
              name: "",
              emailID: "",
              phoneNumber: "",
              address: "",
              userName: "",
              password: "",
              BankID: "",
              Applyingfor: "",
            });

            setTimeout(() => {
              nameRef.current.value = "";
              emailRef.current.value = "";
              phoneNumberRef.current.value = "";
              banknameRef.current.value = "";
              banktypeRef.current.value = "";
              addressRef.current.value = "";
              usernameRef.current.value = "";
              passwordRef.current.value = "";

              navigation("/");
            }, 1000);
          } else {
            toast.warning(response.data.responseMessage);
          }
        })
        .catch((err) => {
          const api = "User/UserRegistration"
          ErrorlogData(DataReferred, err, api)
          console.log(err);
          toast.warning(err);
        });
    } else {
      if (!toastDisplayed) {
        toast.warning("Please fill all mandatory fields", { autoClose: 1000 });
      }
      setToastDisplayed(true);
    }
  };

  useEffect(() => {
    getHandledata();
    if (toastDisplayed) {
      setTimeout(() => {
        setToastDisplayed(false);
      }, 1500);
    }
  }, [toastDisplayed]);

  return (
    <>
      <Helmet>
        <title>Bank/ADLA Register</title>{" "}
      </Helmet>

      <div className="user_auth">
        <div
          className="user_auth_left"
          style={{ background: `url(${background})` }}
        >
          <div className="logo_uth_user">
            <div>
              <img src={logo} />
              <h3>Enhanced Document Management System (eDMS)</h3>
            </div>

            <p className="text-start">
              <p className="text-center mb-0 p-0"><b>BANK/ADLA REGISTRATION FORM</b></p>
              Welcome to the RBZ Enhanced Document Management System (eDMS) (DMS) registration page! To get started with our system, please complete the registration form by providing the necessary details. This information will help us set up your account and ensure you have the appropriate access and permissions.

              <br />
              <br />
              Once you have filled in all the required fields, click the "Sign Up" button to complete your registration. Once the DMS Administrator has reviewed your registration request, you will receive a confirmation email with further instructions related to your account.
              <br />
              <br />
              If you have any questions or need assistance, please contact our support team.
              <br />
              <br />
              Note:
              <br />
              All fields are mandatory. Please ensure that you provide accurate and up-to-date information to avoid any delays in processing your registration.
            </p>
          </div>
        </div>

        <div className="user_auth_right">
          <div className="register_outer">
            <div className="login_inner">
              {/* <div className="login_inner_header">
            <img src={logo} />
            <h3>Enhanced Document Management System (eDMS)</h3>
          </div> */}

              <div className="login_form ">
                <h5>Bank/ADLA REGISTRATION FORM</h5>
              </div>

              <div className="login_form_panel">
                <form>
                  {/* {Object.keys(errors).map((key) => (
                    <div key={key} style={{ color: 'red' }}>{errors[key]}</div>
                ))} */}

                  <div className="row">
                    <div className="col-md-6">
                      <div className="form-bx mb-4">
                        <label>
                          {masterBank?.length ? <select
                            ref={banknameRef}
                            className={
                              errors?.BankID && bankData?.BankID == ""
                                ? "error"
                                : ""
                            }
                            name="BankID"
                            onChange={(e) => {
                              bankdataChangehandle(e);
                            }}
                          >
                            <option value="">Select Bank/ADLA Name</option>
                            {masterBank?.map((item, index) => {
                              return (
                                <>
                                  <option value={item?.id} key={index}>
                                    {item?.bankName}
                                  </option>
                                </>
                              );
                            })}
                          </select>
                            : <div class="spinner-border text-primary" role="status">
                              <span class="visually-hidden">Loading...</span>
                            </div>}
                          <span className="sspan"></span>
                        </label>
                        {errors?.BankID && bankData?.BankID == "" ? (
                          <small className="errormsg">{errors?.BankID}</small>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>

                    <div className="col-md-6">
                      <div className="form-bx mb-4">
                        <label>
                          <select
                            ref={banktypeRef}
                            className={
                              errors?.Applyingfor && bankData?.Applyingfor == ""
                                ? "error"
                                : ""
                            }
                            name="Applyingfor"
                            onChange={(e) => {
                              bankApplyforhamdle(e);
                            }}
                          >
                            <option value="">Select Applying For</option>
                            <option value="Bank/ADLA Capturer">
                              Bank/ADLA Capturer
                            </option>
                            <option value="Bank/ADLA Supervisor">
                              Bank/ADLA Supervisor
                            </option>
                          </select>
                          <span className="sspan"></span>
                        </label>
                        {errors?.Applyingfor && bankData?.Applyingfor == "" ? (
                          <small className="errormsg">
                            {errors?.Applyingfor}
                          </small>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-md-6">
                      <div className="form-bx mb-4">
                        <label>
                          <input
                            type="text"
                            ref={nameRef}
                            className={
                              errors?.name && bankData?.name == ""
                                ? "error"
                                : ""
                            }
                            name="name"
                            placeholder="Full Name"
                            onChange={(e) => {
                              bankdataChangehandle(e);
                            }}
                            value={bankData.name}
                          />
                          <span className="sspan"></span>
                        </label>
                        {errors?.name && bankData?.name == "" ? (
                          <small className="errormsg">{errors?.name}</small>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>

                    <div className="col-md-6">
                      <div className="form-bx mb-4">
                        <label>
                          <input
                            type="text"
                            ref={emailRef}
                            className={errors?.emailID ? "error" : ""}
                            name="emailID"
                            onChange={(e) => {
                              bankdataChangehandle(e);
                            }}
                            placeholder="Email Address"
                            value={bankData.emailID}
                          />
                          <span className="sspan"></span>
                        </label>
                        {errors?.emailID ? (
                          <small className="errormsg">{errors?.emailID}</small>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                  </div>

                  <div className="form-bx mb-4">
                    <label>
                      <input
                        type="number"
                        ref={phoneNumberRef}
                        min={0}
                        maxLength={10}
                        className={errors?.phoneNumber ? "error" : ""}
                        name="phoneNumber"
                        onChange={(e) => {
                          bankdataChangehandle(e);
                        }}
                        placeholder="Contact Number"
                        value={bankData.phoneNumber}
                      />
                      <span className="sspan"></span>
                    </label>
                    {errors?.phoneNumber ? (
                      <small className="errormsg">{errors?.phoneNumber}</small>
                    ) : (
                      ""
                    )}
                  </div>
                  <div className="form-bx mb-4">
                    <label>
                      <input
                        type="text"
                        ref={addressRef}
                        className={
                          errors?.address && bankData?.address == ""
                            ? "error"
                            : ""
                        }
                        name="address"
                        onChange={(e) => {
                          bankdataChangehandle(e);
                        }}
                        placeholder="Address"
                        value={bankData.address}
                      />
                      <span className="sspan"></span>
                    </label>
                    {errors?.address && bankData?.address == "" ? (
                      <small className="errormsg">{errors?.address}</small>
                    ) : (
                      ""
                    )}
                  </div>

                  <div className="row">
                    <div className="col-md-6">
                      <div className="form-bx mb-4">
                        <label>
                          <input
                            type="text"
                            ref={usernameRef}
                            className={errors?.userName ? "error" : ""}
                            name="userName"
                            onChange={(e) => {
                              bankdataChangehandle(e);
                            }}
                            value={bankData.userName}
                            placeholder="Username"
                          />
                          <span className="sspan"></span>
                        </label>
                        {errors?.userName ? (
                          <small className="errormsg">{errors?.userName}</small>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>

                    <div className="col-md-6">
                      <div className="form-bx mb-3">
                        <label>
                          <input
                            type="password"
                            ref={passwordRef}
                            className={errors?.password ? "error" : ""}
                            name="password"
                            value={bankData.password.trim()}
                            onChange={(e) => {
                              bankdataChangehandle(e);
                            }}
                            placeholder="Password"
                          />
                          <span className="sspan"></span>
                        </label>
                        {errors?.password ? (
                          <small className="errormsg">{errors?.password}</small>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                  </div>

                  <div className="form-footer">
                    <Link to="/" className="register">
                      Login
                    </Link>
                    <button
                      type="button"
                      onClick={(e) => handleSubmit(e)}
                      className="login"
                    >
                      Signup
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default BankRegister;
