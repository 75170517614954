export const RBZEMAIL = "@rbz.co.zw";

const currentHost = `${window.location.protocol}//${window.location.hostname}`;

//const currentHost = "https://dev.dmsupgrade.in/API";

//export const APIURL = `${currentHost}/dmsapi/`;
//export const ImageAPI = `${currentHost}/dmsfs/`; // comment by me
//export const ViewImageAPI = `${currentHost}/Files/`; // comment by me

// -----------------------------------------
//For dev.dmsupgrade and getanapp
export const APIURL = "https://dev.dmsupgrade.in/API/"; // this is for dev
export const ImageAPI = "https://dev.dmsupgrade.in/FilesAPI/"; // this is for dev
export const ViewImageAPI = "https://dev.dmsupgrade.in/Files/"; // this is for dev

// for UAT
//export const APIURL = "https://uatdms.rbz.co.zw/uatdmsapi/";// this is for UAT
//export const ImageAPI = "https://uatdms.rbz.co.zw/uatdmsfs/";// this is for UAT
//export const ViewImageAPI = "https://uatdms.rbz.co.zw/Files/";// this is for UAT
