import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import DashboardLayout from "../components/DashboardLayout";
import { Helmet } from "react-helmet";
import { Storage } from "../login/Storagesetting";
import { APIURL } from "../constant";
import axios from "axios";
import ErrorsLogApi from "../components/ErrorslogApi";
import ActingRoleINSReferredDashboardTable from "../tables/ActingRoleINSReferredDashboardTable";

const ActingRoleINSReferredDashboard = () => {
  const { ErrorlogData } = ErrorsLogApi();
  const menuname = sessionStorage.getItem("menuname");
  const submenuname = sessionStorage.getItem("submenuname");
  const RoleId = Storage.getItem("roleIDs");
  const [userselect, setuserselect] = useState("All");
  const [actionUserList, setactionUserList] = useState([]);
  const useId = Storage.getItem("userID");

  const clickhandlerole = () => {
    sessionStorage.setItem("submenuname", "Dashboard");
  };

  const handleselectUser = (e) => {
    setuserselect(e.target.value);
  };

  const HandleUSerList = () => {
    const DataReferred = {
      UserID: useId.replace(/"/g, ""),
    }
    axios
      .post(APIURL + "User/GetUsersData", DataReferred)
      .then((res) => {
        if (res.data.responseCode == "200") {
          setactionUserList(res.data.responseData);
        } else {
          setactionUserList([]);
        }
      })
      .catch((err) => {
        const api = "User/GetUsersData"
        ErrorlogData(DataReferred, err, api)
        console.log("Action User List", err);
      });
  };

  useEffect(() => {
    HandleUSerList();
  }, []);

  return (
    <>
      <Helmet>
        <title>
          {menuname ? menuname : "Financial Surveillance"}{" "}
          {submenuname ? submenuname : "Acting Role"}
        </title>{" "}
      </Helmet>
      <DashboardLayout>
        <nav>
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <Link to="/ImportDashboard">Financial Surveillance</Link>
            </li>
            <li className="breadcrumb-item active">Referred Acting Role</li>
          </ol>
        </nav>

        <section className="section dashboard">
          <div className="row">
            <div className="col-md-12">
              <div className="datatable">
                <h4 className="section_top_heading">
                  SUBMITTED REQUESTS
                  {RoleId == "6" || RoleId == "7" || RoleId == "8" ? (
                    <div className="box-userselect">
                      <select
                        className="userselect"
                        name=""
                        onChange={(e) => handleselectUser(e)}
                      >
                        {actionUserList?.map((item, index) => {
                          return (
                            <>
                              <option value={item.userID} key={index}>
                                {item.userName}
                              </option>
                            </>
                          );
                        })}
                      </select>
                      <Link
                        to="/INSDashboard"
                        className="act_btn-dashboard"
                        onClick={clickhandlerole}
                      >
                        Back To Dashboard
                      </Link>
                    </div>
                  ) : (
                    ""
                  )}
                </h4>
                <ActingRoleINSReferredDashboardTable
                  userselect={userselect}
                  actionUserList={actionUserList}
                />
              </div>
            </div>
          </div>
        </section>
      </DashboardLayout>
    </>
  );
};

export default ActingRoleINSReferredDashboard;
