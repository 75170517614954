import React, { useState, useRef, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import generatePDF, { Resolution, Margin } from "react-to-pdf";
import DataTable from "react-data-table-component";
import { CSVLink } from "react-csv";
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
import { Link } from "react-router-dom";
import { APIURL } from "../../constant";
import ErrorsLogApi from "../components/ErrorsLogApi";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { toast } from "react-toastify";
import EditorPage from "../components/EditorTemplate";
import { Storage } from "../../login/Storagesetting";
import { TailSpin } from "react-loader-spinner";
import { decode } from "html-entities";
import jsPDF from "jspdf";

import axios from "axios";
const BankMasterTable = () => {
  const navigate = useNavigate();
  const { ErrorlogData } = ErrorsLogApi();
  const [imageDimensions, setImageDimensions] = useState({});
  const [searchText, setSearchText] = useState("");
  const [tableData, setTableData] = useState([]);
  const [toastDisplayed, setToastDisplayed] = useState(false);
  const csvLinkRef = useRef();
  const [errors, setErrors] = useState(false);
  const [loader, setLoader] = useState(false);
  const [showdataLoader, setshowdataLoader] = useState(false);
  const [formerr, setformerr] = useState();
  const targetRef = useRef();
  const [updateerr, setupdateerr] = useState();
  const [banknameEditpdfF, setbanknameEditpdfF] = useState("");

  const [showUpdateModal, setShowUpdateModal] = useState(false);
  const [getNewBankData, setNewGetBankData] = useState();
  const [updateData, setUpdateData] = useState({
    bankCode: "",
    bankName: "",
    bankAddress1: "",
    bankAddress2: "",
    bankAddress3: "",
    approvalLetter: "",
    nameForSign: "",
    designationForSign: "",
    signatureImage: "",
    status: "",
    exportEmail: "",
    importEmail: "",
    finEmail: "",
    insEmail: "",
  });

  const UpdateModalClose = () => {
    setShowUpdateModal(false);
    setUpdateData({
      bankCode: "",
      bankName: "",
      bankAddress1: "",
      bankAddress2: "",
      bankAddress3: "",
      approvalLetter: "",
      nameForSign: "",
      designationForSign: "",
      signatureImage: "",
      status: "",
      exportEmail: "",
      importEmail: "",
      finEmail: "",
      insEmail: "",
    });
    setupdateerr("");
  };

  const changeUpdateForm = (e) => {
    const { name, value } = e.target;
    let newErrors = {};
    const specialCharsOLD = /[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;
    const spaceCheck = /\s{2,}/g;
    if (name == "bankName" && specialCharsOLD.test(value)) {
      newErrors.bankName = "Special characters not allowed.";
    } else if (name == "bankName" && value.charAt(0) === " ") {
      newErrors.bankName = "First character cannot be a blank space.";
    } else if (name == "bankName" && spaceCheck.test(value)) {
      newErrors.bankName = "Multiple space not allow.";
    } else if (name == "bankCode" && specialCharsOLD.test(value)) {
      newErrors.bankCode = "Special characters not allowed.";
    } else if (name == "bankCode" && value.charAt(0) === " ") {
      newErrors.bankCode = "First character cannot be a blank space.";
    } else if (name == "bankCode" && spaceCheck.test(value)) {
      newErrors.bankCode = "Multiple space not allow.";
    } else if (name == "bankAddress1" && value.charAt(0) === " ") {
      newErrors.bankAddress1 = "First character cannot be a blank space.";
    } else if (name == "bankAddress1" && spaceCheck.test(value)) {
      newErrors.bankAddress1 = "Multiple space not allow.";
    } else if (
      name == "bankAddress1" &&
      (value.includes("$") ||
        value.includes("@") ||
        value.includes("`") ||
        value.includes("|") ||
        value.includes("~") ||
        value.includes(",") ||
        value.includes(">") ||
        value.includes("<") ||
        value.includes("*") ||
        value.includes("&") ||
        value.includes("%") ||
        value.includes("#") ||
        value.includes("+") ||
        value.includes("?") ||
        value.includes("!") ||
        value.includes(";") ||
        value.includes("=") ||
        value.includes('"') ||
        value.includes(`'`) ||
        value.includes("/") ||
        value.includes("}") ||
        value.includes("{") ||
        value.includes("^") ||
        value.includes("\\") ||
        value.includes("]") ||
        value.includes("["))
    ) {
      newErrors.bankAddress1 = "Special characters not allowed.";
    } else {
      setUpdateData((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    }
    setupdateerr(newErrors);
  };
  // pdfID
  const [pdfID, setPdfID] = useState(null);
  const handlePdfId = (id) => {
    setPdfID(id);
  };
  const [updateID, setUpdateID] = useState("");

  const handleUpdate = async (id) => {
    setshowdataLoader(true);
    setShowUpdateModal(true);
    setUpdateID(id);
    const TableId = {
      id: id,
    };
    try {
      const response = await fetch(APIURL + "Admin/GetBankByID", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(TableId),
      });
      const data = await response.json();
      if (data.responseCode == "200") {
        setUpdateData(data.responseData);
        setshowdataLoader(false);
      } else if (data.responseMessage == "No Data") {
        setshowdataLoader(false);
      }
    } catch (error) {
      const api = "Admin/GetBankByID";
      ErrorlogData(TableId, error, api);
      console.error("Error fetching data:", error);
    }
  };

  const [getBankData, setGetBankData] = useState();

  const getBankDataByID = async (id) => {
    const DataReferred = {
      id: id,
    };
    try {
      const response = await axios.post(
        APIURL + "Admin/GetBankByID",
        DataReferred
      );
      setGetBankData(response.data.responseData);
    } catch (error) {
      const api = "Admin/GetBankByID";
      ErrorlogData(DataReferred, error, api);
      console.error("Error fetching data:", error);
    }
  };

  const handleUpdateData = async (e) => {
    e.preventDefault();
    const updateValue = {
      ID: updateID,
      BankCode: updateData.bankCode.toUpperCase(),
      BankName: updateData.bankName,
      BankAddress1: updateData.bankAddress1,
      BankAddress2: updateData.bankAddress2,
      BankAddress3: updateData.bankAddress3,
      ExportEmail: updateData.exportEmail,
      ImportEmail: updateData.importEmail,
      FINEmail: updateData.finEmail,
      INSEmail: updateData.insEmail,
      NameForSign: "",
      DesignationForSign: "",
      SignatureImage: "",
      Status: updateData.status,
    };
    const updateValue_json = JSON.stringify(updateValue);

    try {
      if (isUpdateValid()) {
        const response = await fetch(APIURL + "Admin/UpdateBank", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: updateValue_json,
        });
        const data = await response.json();
        if (data.responseCode === "200") {
          setToastDisplayed(true);
          toast.success(data.responseMessage, { autoClose: 2000 });
          setTimeout(() => {
            UpdateModalClose();
            table_Data();
            setToastDisplayed(false);
            setUpdateData({
              bankCode: "",
              bankName: "",
              bankAddress1: "",
              bankAddress2: "",
              bankAddress3: "",
              exportEmail: "",
              importEmail: "",
              finEmail: "",
              insEmail: "",
            });
            setSearchText("");
            setErrors(false);
          }, 2500);
        } else {
          toast.warning(data.responseMessage, { autoClose: 2000 });
          setTimeout(() => {
            UpdateModalClose();
            table_Data();
            setToastDisplayed(false);
            setUpdateData({
              bankCode: "",
              bankName: "",
              bankAddress1: "",
              bankAddress2: "",
              bankAddress3: "",
              exportEmail: "",
              importEmail: "",
              finEmail: "",
              insEmail: "",
            });
            setErrors(false);
          }, 2500);
        }
      }
    } catch (error) {
      const api = "Admin/UpdateBank";
      ErrorlogData(updateValue_json, error, api);
      console.error("Error fetching data:", error);
    }
  };
  // bank update end
  const table_Data = async () => {
    setLoader(true);
    try {
      const response = await fetch(APIURL + "Admin/GetAllBanks", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
      });
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const data = await response.json();
      // setTableData(data.responseData);
      if (data.responseCode == "200") {
        setTableData(data.responseData);
        setLoader(false);
      } else if (data.responseMessage == "No Data") {
        setLoader(false);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const handleBankname = (name) => {
    setbanknameEditpdfF(name);
  };

  const options = {
    method: "open",
    resolution: Resolution.HIGH,
    page: {
      margin: Margin.SMALL,
      unit: "mm",
      format: "a4",
      putOnlyUsedFonts: true,
      orientation: "landscape",
      height: 297,
      width: 219,
    },
    canvas: {
      mimeType: "image/png",
      qualityRatio: 1,
    },
    overrides: {
      pdf: {
        compress: true,
      },
      canvas: {
        useCORS: true,
      },
    },
  };

  useEffect(() => {
    /*if (pdfID !== null) {
        generatePDF(targetRef, { filename: "page.pdf", options })
        .then((res) => {
          setPdfID(null);
          setLoader("");
        })
        .catch((err) => {
          setPdfID(null);
          setLoader("");
        });
    }*/
    table_Data();
  }, [pdfID]);

  const columns = [
    {
      name: "Bank Code",
      selector: (row) => row.bankCode,
      sortable: true,
      searchable: true,
      width: "200px",
    },
    {
      name: "Bank Name",
      selector: (row) => row.bankName,
      sortable: true,
      searchable: true,
      width: "200px",
    },
    {
      name: "Address Line",
      selector: (row) =>
        (row.bankAddress1 ? row.bankAddress1 : " ") +
        "  " +
        (row.bankAddress2 ? " , " + row.bankAddress2 : " ") +
        (row.bankAddress3 ? " , " + row.bankAddress3 : " "),
      sortable: true,
      searchable: true,
      width: "400px",
    },
    /*{
      name: "Letterhead",
      selector: (row) => row.id,
      cell: (row) => (
        row.bankLetterHead ? <button
        type="button"
        key={row.id}
        className="me-2"
        onClick={() => {
          setPdfID(null);
          setPdfID(row.id);
          setLoader(row.id);
          //generateLaterHeadPdf(row.id);
        }}
        style={{
          border: "unset",
          backgroundColor: "transparent",
          fontSize: "25px",
        }}
      >
        {loader == row.id ? (
          <TailSpin
            visible={true}
            height="20"
            width="20"
            color="#5e62a1"
            ariaLabel="tail-spin-loading"
            radius="1"
            wrapperStyle={{}}
            wrapperClass=""
          />
        ) : (
          <i className="bi bi-file-text"></i>
        )}
      </button> : ""
      ),
      searchable: true,
    },*/
    {
      name: "Status",
      selector: (row) => row.status,
      sortable: true,
      searchable: true,
      width: "100px",
      cell: (row) => (
        <span>
          {row.status === 1 ? (
            <span className="badge rounded-pill bg-success">Active</span>
          ) : (
            <span className="badge rounded-pill bg-warning text-dark">
              Inactive
            </span>
          )}
        </span>
      ),
    },
    {
      name: "Action",
      width: "100px",
      cell: (row) => (
        <>
          <Link
            to=""
            className="me-2"
            title="Bank Edit"
            onClick={() => handleUpdate(row.id)}
          >
            <i className="bi bi-pencil-square"></i>
          </Link>
          <span
            title="Letterhead Edit"
            onClick={() => {
              handleEditorPage(row.id);
              getBankDataByID(row.id);
              handleBankname(row.bankName);
            }}
          >
            {/* <i className="bi bi-pencil-fill"></i> */}
            <i class="bi bi-file-earmark-text letterHeadAddIcon"></i>
          </span>
        </>
      ),
    },
  ];

  // Sector Master form start
  const [showForm, setShowForm] = useState(false);
  const handleFormClose = () => {
    setShowForm(false);
    setformerr("");
    setBankForm({
      BankCode: "",
      BankName: "",
      BankAddress1: "",
      BankAddress2: "",
      BankAddress3: "",
      ExportEmail: "",
      ImportEmail: "",
      FinEmail: "",
      InsEmail: "",
    });
  };
  const handleFormShow = () => setShowForm(true);
  const [bankForm, setBankForm] = useState({
    BankCode: "",
    BankName: "",
    BankAddress1: "",
    BankAddress2: "",
    BankAddress3: "",
    ExportEmail: "",
    ImportEmail: "",
    FinEmail: "",
    InsEmail: "",
  });

  const changeHandelForm = (e) => {
    const { name, value } = e.target;
    let newErrors = {};
    const spaceCheck = /\s{2,}/g;
    const specialCharsOLD = /[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;
    if (name == "BankName" && specialCharsOLD.test(value)) {
      newErrors.BankName = "Special characters not allowed.";
    } else if (name == "BankName" && value.charAt(0) === " ") {
      newErrors.BankName = "First character cannot be a blank space.";
    } else if (name == "BankName" && spaceCheck.test(value)) {
      newErrors.BankName = "Multiple space not allow.";
    } else if (name == "BankCode" && specialCharsOLD.test(value)) {
      newErrors.BankCode = "Special characters not allowed.";
    } else if (name == "BankCode" && value.charAt(0) === " ") {
      newErrors.BankCode = "First character cannot be a blank space.";
    } else if (name == "BankCode" && spaceCheck.test(value)) {
      newErrors.BankCode = "Multiple space not allow.";
    } else if (name == "BankAddress1" && value.charAt(0) === " ") {
      newErrors.BankAddress1 = "First character cannot be a blank space.";
    } else if (name == "BankAddress1" && spaceCheck.test(value)) {
      newErrors.BankAddress1 = "Multiple space not allow.";
    } else if (
      name == "BankAddress1" &&
      (value.includes("$") ||
        value.includes("@") ||
        value.includes("`") ||
        value.includes("|") ||
        value.includes("~") ||
        value.includes(",") ||
        value.includes(">") ||
        value.includes("<") ||
        value.includes("*") ||
        value.includes("&") ||
        value.includes("%") ||
        value.includes("#") ||
        value.includes("+") ||
        value.includes("?") ||
        value.includes("!") ||
        value.includes(";") ||
        value.includes("=") ||
        value.includes('"') ||
        value.includes(`'`) ||
        value.includes("/") ||
        value.includes("}") ||
        value.includes("{") ||
        value.includes("^") ||
        value.includes("\\") ||
        value.includes("]") ||
        value.includes("["))
    ) {
      newErrors.BankAddress1 = "Special characters not allowed.";
    } else {
      setBankForm((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    }
    setformerr(newErrors);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const application_data = {
      BankCode: bankForm.BankCode.toUpperCase(),
      BankName: bankForm.BankName,
      BankAddress1: bankForm.BankAddress1,
      BankAddress2: bankForm.BankAddress2,
      BankAddress3: bankForm.BankAddress3,
      ApprovalLetter: "",
      NameForSign: "",
      DesignationForSign: "",
      SignatureImage: "",
      ExportEmail: bankForm.ExportEmail,
      ImportEmail: bankForm.ImportEmail,
      FINEmail: bankForm.FinEmail,
      INSEmail: bankForm.InsEmail,
    };
    const application_data_json = JSON.stringify(application_data);
    try {
      if (isValid()) {
        const application_responce = await fetch(APIURL + "Admin/AddBank", {
          method: "Post",
          headers: {
            "Content-Type": "application/json",
          },
          body: application_data_json,
        });
        const data = await application_responce.json();
        setToastDisplayed(true);
        if (data.responseCode === "200") {
          toast.success(data.responseMessage, { autoClose: 2000 });
          setTimeout(() => {
            handleFormClose();
            table_Data();
            setBankForm({
              BankCode: "",
              BankName: "",
              BankAddress1: "",
              BankAddress2: "",
              BankAddress3: "",
              exportEmail: "",
              importEmail: "",
              finEmail: "",
              insEmail: "",
            });
            setSearchText("");
            setToastDisplayed(false);
          }, 2500);
        } else {
          toast.warning(data.responseMessage, { autoClose: 2000 });
          setTimeout(() => {
            handleFormClose();
            table_Data();
            setToastDisplayed(false);
          }, 2500);
        }
      }
    } catch (error) {
      const api = "Admin/AddBank";
      ErrorlogData(application_data_json, error, api);
      console.log("Fetching Error", error);
    }
  };

  // BankMaster form end
  // validation start
  const isValid = () => {
    const emailRegex = /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Z|a-z]{2,}$/;
    const newErrors = {};
    let valid = true;

    if (!bankForm.BankCode) {
      newErrors.BankCode = "Bank code is required.";
      valid = false;
    }
    if (!bankForm.BankName) {
      newErrors.BankName = "Bank name is required ";
      valid = false;
    }
    if (!bankForm.BankAddress1) {
      newErrors.BankAddress1 = "Bank address1 is required ";
      valid = false;
    }
    //  if (bankForm?.emailID === "") {
    //   newErrors.emailID = "Email is required";
    //   valid = false;

    // }
    if (
      bankForm?.ExportEmail?.length > 0 &&
      !emailRegex.test(bankForm?.ExportEmail)
    ) {
      newErrors.ExportEmail = "Please enter valid email Id";
      valid = false;
    }
    if (
      bankForm?.ImportEmail?.length > 0 &&
      !emailRegex.test(bankForm?.ImportEmail)
    ) {
      newErrors.ImportEmail = "Please enter valid email Id";
      valid = false;
    }
    if (
      bankForm?.FinEmail?.length > 0 &&
      !emailRegex.test(bankForm?.FinEmail)
    ) {
      newErrors.FinEmail = "Please enter valid email Id";
      valid = false;
    }
    if (
      bankForm?.InsEmail?.length > 0 &&
      !emailRegex.test(bankForm?.InsEmail)
    ) {
      newErrors.InsEmail = "Please enter valid email Id";
      valid = false;
    }
    setformerr(newErrors);
    return valid;
  };
  // validation end

  // validation update start
  const isUpdateValid = () => {
    const emailRegex = /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Z|a-z]{2,}$/;
    const newErrors = {};
    let valid = true;

    if (!updateData.bankCode) {
      newErrors.bankCode = "Bank code is required.";
      valid = false;
    }

    if (!updateData.bankName) {
      newErrors.bankName = "Bank name is required.";
      valid = false;
    }
    if (!updateData.bankAddress1) {
      newErrors.bankAddress1 = "Bank address1 is required.";
      valid = false;
    }
    if (
      updateData?.exportEmail?.length > 0 &&
      !emailRegex.test(updateData?.exportEmail)
    ) {
      newErrors.exportEmail = "Please enter valid email Id";
      valid = false;
    }
    if (
      updateData?.importEmail?.length > 0 &&
      !emailRegex.test(updateData?.importEmail)
    ) {
      newErrors.importEmail = "Please enter valid email Id";
      valid = false;
    }
    if (
      updateData?.finEmail?.length > 0 &&
      !emailRegex.test(updateData?.finEmail)
    ) {
      newErrors.finEmail = "Please enter valid email Id";
      valid = false;
    }
    if (
      updateData?.insEmail?.length > 0 &&
      !emailRegex.test(updateData?.insEmail)
    ) {
      newErrors.insEmail = "Please enter valid email Id";
      valid = false;
    }
    setupdateerr(newErrors);
    return valid;
  };

  // goto editorTemplate Page
  const [showEditor, setShowEditor] = useState(false);
  const [bankId, setBankId] = useState(false);
  const handleEditorPage = (id) => {
    setBankId(id);
    setShowEditor(true);
  };

  // validation end
  const filteredData = tableData?.filter(
    (item) =>
      item.bankName?.toLowerCase().includes(searchText.toLowerCase()) ||
      item.bankCode?.toLowerCase().includes(searchText.toLowerCase()) ||
      (item.id && item.id.toString().includes(searchText)) ||
      (item.status == "1" ? "Active" : "Inactive")
        .toLowerCase()
        .includes(searchText?.toLowerCase())
  );

  /*const Header = () => (
    <div
      className="headerPDF"
      style={{
        width: "100%",
        maxHeight: "120px",
        textAlign: "center",
        overflow: "hidden",
      }}
    >
      {tableData.map((item) => {
        if (item.id == pdfID) {
          return <img src={item.headerImageURL} alt="Banner Preview" />;
        }
      })}
    </div>
  );

  const Header2 = () => (
    <div className="headerPDF2">
      {tableData.map((item) => {
        if (item.id == pdfID) {
          return <img src={item.headerImageURL} alt="Banner Preview" />;
        }
      })}
    </div>
  );

  function splitStringIntoArray(str, limit) {
    const regex = new RegExp(`.{1,${limit}}`, "g");
    const matches = str.match(regex);
    return matches || [];
  }

  const characterLimit = 2400;
  const Footer = () => (
    <div
      className="FooterPDF"
      style={{
        width: "100%",
        maxHeight: "100px",
        position: "absolute",
        bottom: "0px",
        display: "flex",
        alignItems: "center",
      }}
    >
      {tableData.map((item) => {
        if (item.id == pdfID) {
          return <img src={item.footerImageURL} alt="Banner Preview" />;
        }
      })}
    </div>
  );

  const Footer2 = () => (
    <div className="FooterPDF2">
      {tableData.map((item) => {
        if (item.id == pdfID) {
          return <img src={item.footerImageURL} alt="Banner Preview" />;
        }
      })}
    </div>
  );
*/

  return (
    <>
      {showEditor ? (
        <EditorPage
          bankID={bankId}
          setbanknameEditpdfF={setbanknameEditpdfF}
          banknameEditpdfF={banknameEditpdfF}
          getBankData={getBankData}
        />
      ) : (
        <>
          {loader == true ? (
            <label className="outerloader2">
              {" "}
              <span className="loader"></span>
              <span className="loaderwait">Please Wait...</span>
            </label>
          ) : (
            <DataTable
              className="bankDataTable"
              columns={columns}
              data={filteredData}
              persistTableHead={true}
              defaultSortFieldId={1}
              defaultSortAsc={true}
              pagination
              paginationRowsPerPageOptions={[10, 50, 100]}
              highlightOnHover
              dense
              striped
              fixedHeader
              subHeader
              subHeaderComponent={
                <div className="admintablesearch">
                  <div className="tablesearch_bx">
                    <input
                      type="text"
                      placeholder="Search"
                      value={searchText}
                      onChange={(e) => setSearchText(e.target.value)}
                    />
                  </div>
                  <div className="table-btn-bx">
                    <Button onClick={handleFormShow}>Add Bank</Button>
                  </div>
                </div>
              }
            />
          )}
        </>
      )}
      {/* BankMaster Form modal */}
      <Modal
        show={showForm}
        onHide={handleFormClose}
        backdrop="static"
        size="lg"
      >
        <div className="application-box editmodal-change">
          <div className="login_inner">
            <div className="login_form ">
              <h5>
                <Modal.Header closeButton className="p-0">
                  <Modal.Title>Add Bank</Modal.Title>
                </Modal.Header>
              </h5>
            </div>
            <div className="login_form_panel">
              <Modal.Body className="p-0">
                <div className="row">
                  <div className="col-md-6">
                    <div className="form-bx mb-4">
                      <p className="form-label">
                        Bank Code <span style={{ color: "#ff0000" }}>*</span>
                      </p>
                      <label>
                        <input
                          type="text"
                          name="BankCode"
                          value={bankForm?.BankCode}
                          className="fomcontrol text-uppercase"
                          placeholder="Bank Code"
                          onChange={(e) => {
                            changeHandelForm(e);
                          }}
                          required
                        />
                        <span className="sspan"></span>
                      </label>
                      {formerr?.BankCode ? (
                        <span className="errormsg">{formerr?.BankCode}</span>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-bx mb-4">
                      <p className="form-label">
                        Bank Name <span style={{ color: "#ff0000" }}>*</span>
                      </p>
                      <label>
                        <input
                          type="text"
                          name="BankName"
                          value={bankForm?.BankName}
                          className="fomcontrol"
                          placeholder="Bank Name"
                          onChange={(e) => {
                            changeHandelForm(e);
                          }}
                          required
                        />
                        <span className="sspan"></span>
                      </label>
                      {formerr?.BankName ? (
                        <span className="errormsg">{formerr?.BankName}</span>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>

                  <div className="col-md-6">
                    <div className="form-bx mb-4">
                      <p className="form-label">Export Email </p>
                      <label>
                        <input
                          type="text"
                          name="ExportEmail"
                          value={bankForm?.ExportEmail}
                          className="fomcontrol"
                          placeholder="Export Email"
                          onChange={(e) => {
                            changeHandelForm(e);
                          }}
                          required
                        />
                        <span className="sspan"></span>
                      </label>
                      {formerr?.ExportEmail ? (
                        <span className="errormsg">{formerr?.ExportEmail}</span>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-bx mb-4">
                      <p className="form-label">Import Email</p>
                      <label>
                        <input
                          type="text"
                          name="ImportEmail"
                          value={bankForm?.ImportEmail}
                          className="fomcontrol"
                          placeholder="Import Email"
                          onChange={(e) => {
                            changeHandelForm(e);
                          }}
                          required
                        />
                        <span className="sspan"></span>
                      </label>
                      {formerr?.ImportEmail ? (
                        <span className="errormsg">{formerr?.ImportEmail}</span>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-bx mb-4">
                      <p className="form-label">Foreign Investment Email</p>
                      <label>
                        <input
                          type="text"
                          name="FinEmail"
                          value={bankForm?.FinEmail}
                          className="fomcontrol"
                          placeholder="Foreign Investment Email"
                          onChange={(e) => {
                            changeHandelForm(e);
                          }}
                          required
                        />
                        <span className="sspan"></span>
                      </label>
                      {formerr?.FinEmail ? (
                        <span className="errormsg">{formerr?.FinEmail}</span>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-bx mb-4">
                      <p className="form-label">Financial Surveillance Email </p>
                      <label>
                        <input
                          type="text"
                          name="InsEmail"
                          value={bankForm?.InsEmail}
                          className="fomcontrol"
                          placeholder="Financial Surveillance Email"
                          onChange={(e) => {
                            changeHandelForm(e);
                          }}
                          required
                        />
                        <span className="sspan"></span>
                      </label>
                      {formerr?.InsEmail ? (
                        <span className="errormsg">{formerr?.InsEmail}</span>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-bx mb-4">
                      <p className="form-label">
                        Bank Address1{" "}
                        <span style={{ color: "#ff0000" }}>*</span>
                      </p>
                      <label>
                        <input
                          type="text"
                          name="BankAddress1"
                          value={bankForm?.BankAddress1}
                          className="fomcontrol"
                          placeholder="Address Line1"
                          onChange={(e) => {
                            changeHandelForm(e);
                          }}
                          required
                        />
                        <span className="sspan"></span>
                      </label>
                      {formerr?.BankAddress1 ? (
                        <span className="errormsg">
                          {formerr?.BankAddress1}
                        </span>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-bx mb-4">
                      <p className="form-label">Bank Address2 </p>
                      <label>
                        <input
                          type="text"
                          name="BankAddress2"
                          placeholder="Address Line2"
                          onChange={(e) => {
                            changeHandelForm(e);
                          }}
                          required
                        />
                        <span className="sspan"></span>
                      </label>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-bx mb-4">
                      <p className="form-label">Bank Address3</p>
                      <label>
                        <input
                          type="text"
                          name="BankAddress3"
                          placeholder="Address Line3"
                          onChange={(e) => {
                            changeHandelForm(e);
                          }}
                          required
                        />
                        <span className="sspan"></span>
                      </label>
                    </div>
                  </div>
                </div>
              </Modal.Body>
              <Modal.Footer className="p-0">
                <Button
                  variant="primary"
                  onClick={handleSubmit}
                  disabled={toastDisplayed ? true : false}
                >
                  Submit
                </Button>
              </Modal.Footer>
            </div>
          </div>
        </div>
      </Modal>

      {/* BankMaster Form update modal */}
      <Modal
        show={showUpdateModal}
        onHide={UpdateModalClose}
        backdrop="static"
        size="lg"
      >
        <div className="application-box editmodal-change">
          <div className="login_inner">
            <div className="login_form ">
              <h5>
                <Modal.Header closeButton className="p-0">
                  <Modal.Title>Update Bank</Modal.Title>
                </Modal.Header>
              </h5>
            </div>
            <div className="login_form_panel">
              {showdataLoader == true ? (
                <label className="outerloader2">
                  <span className="loader"></span>
                  <span className="loaderwait">Please Wait...</span>
                </label>
              ) : (
                <>
                  <Modal.Body className="p-0">
                    <div className="row">
                      <div className="col-md-6">
                        <div className="form-bx mb-4">
                          <p className="form-label">
                            Bank Code{" "}
                            <span style={{ color: "#ff0000" }}>*</span>
                          </p>
                          <label>
                            <input
                              type="text"
                              name="bankCode"
                              className="fomcontrol text-uppercase"
                              placeholder="Bank Code"
                              onChange={(e) => {
                                changeUpdateForm(e);
                              }}
                              value={updateData.bankCode}
                            />
                            <span className="sspan"></span>
                          </label>
                          {updateerr?.bankCode ? (
                            <span className="errormsg">
                              {updateerr?.bankCode}
                            </span>
                          ) : (
                            ""
                          )}
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-bx mb-4">
                          <p className="form-label">
                            Bank Name{" "}
                            <span style={{ color: "#ff0000" }}>*</span>
                          </p>
                          <label>
                            <input
                              type="text"
                              name="bankName"
                              className="fomcontrol"
                              value={updateData?.bankName}
                              placeholder="Bank Name"
                              onChange={(e) => {
                                changeUpdateForm(e);
                              }}
                            />
                            <span className="sspan"></span>
                          </label>
                          {updateerr?.bankName ? (
                            <span className="errormsg">
                              {updateerr?.bankName}
                            </span>
                          ) : (
                            ""
                          )}
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-bx mb-4">
                          <p className="form-label">Export Email </p>
                          <label>
                            <input
                              type="text"
                              name="exportEmail"
                              className="fomcontrol"
                              value={updateData.exportEmail}
                              placeholder="Export Email"
                              onChange={(e) => {
                                changeUpdateForm(e);
                              }}
                            />
                            <span className="sspan"></span>
                          </label>
                          {updateerr?.exportEmail ? (
                            <span className="errormsg">
                              {updateerr?.exportEmail}
                            </span>
                          ) : (
                            ""
                          )}
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-bx mb-4">
                          <p className="form-label">Import Email </p>
                          <label>
                            <input
                              type="text"
                              name="importEmail"
                              value={updateData.importEmail}
                              className="fomcontrol"
                              placeholder="Import Email"
                              onChange={(e) => {
                                changeUpdateForm(e);
                              }}
                            />
                            <span className="sspan"></span>
                          </label>
                          {updateerr?.importEmail ? (
                            <span className="errormsg">
                              {updateerr?.importEmail}
                            </span>
                          ) : (
                            ""
                          )}
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-bx mb-4">
                          <p className="form-label">Foreign Investment Email</p>
                          <label>
                            <input
                              type="text"
                              name="finEmail"
                              className="fomcontrol"
                              value={updateData.finEmail}
                              placeholder="Foreign Investment Email"
                              onChange={(e) => {
                                changeUpdateForm(e);
                              }}
                            />
                            <span className="sspan"></span>
                          </label>
                          {updateerr?.finEmail ? (
                            <span className="errormsg">
                              {updateerr?.finEmail}
                            </span>
                          ) : (
                            ""
                          )}
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-bx mb-4">
                          <p className="form-label">Financial Surveillance Email </p>
                          <label>
                            <input
                              type="text"
                              name="insEmail"
                              className="fomcontrol"
                              value={updateData.insEmail}
                              placeholder="Financial Surveillance Email"
                              onChange={(e) => {
                                changeUpdateForm(e);
                              }}
                            />
                            <span className="sspan"></span>
                          </label>
                          {updateerr?.insEmail ? (
                            <span className="errormsg">
                              {updateerr?.insEmail}
                            </span>
                          ) : (
                            ""
                          )}
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-bx mb-4">
                          <p className="form-label">
                            Bank Address1{" "}
                            <span style={{ color: "#ff0000" }}>*</span>
                          </p>
                          <label>
                            <input
                              type="text"
                              name="bankAddress1"
                              className="fomcontrol"
                              placeholder="Address1"
                              onChange={(e) => {
                                changeUpdateForm(e);
                              }}
                              value={updateData.bankAddress1}
                            />
                            <span className="sspan"></span>
                          </label>
                          {updateerr?.bankAddress1 ? (
                            <span className="errormsg">
                              {updateerr?.bankAddress1}
                            </span>
                          ) : (
                            ""
                          )}
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-bx mb-4">
                          <p className="form-label">Bank Address2</p>
                          <label>
                            <input
                              type="text"
                              name="bankAddress2"
                              className=""
                              placeholder="Address2"
                              onChange={(e) => {
                                changeUpdateForm(e);
                              }}
                              value={updateData.bankAddress2}
                            />
                            <span className="sspan"></span>
                          </label>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-bx mb-4">
                          <p className="form-label">Bank Address3 </p>
                          <label>
                            <input
                              type="text"
                              name="bankAddress3"
                              className=""
                              placeholder="Address3"
                              onChange={(e) => {
                                changeUpdateForm(e);
                              }}
                              required
                              value={updateData.bankAddress3}
                            />
                            <span className="sspan"></span>
                          </label>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-bx mb-4">
                          <p className="form-label">Status</p>
                          <label>
                            <select
                              name="status"
                              className=""
                              aria-label="Large select example"
                              onChange={(e) => {
                                changeUpdateForm(e);
                              }}
                              value={updateData.status}
                            >
                              <option value="0">Inactive</option>
                              <option value="1">Active</option>
                            </select>
                            <span className="sspan"></span>
                          </label>
                        </div>
                      </div>
                    </div>
                  </Modal.Body>
                  <Modal.Footer className="p-0">
                    <Button
                      variant="primary"
                      onClick={handleUpdateData}
                      disabled={toastDisplayed ? true : false}
                    >
                      Update
                    </Button>
                  </Modal.Footer>
                </>
              )}
            </div>
          </div>
        </div>
      </Modal>

      {tableData?.map((item) => {
        if (item.id == pdfID) {
          const inputString = item.bankLetterHead;
          return (
            <>
              {inputString?.length > 1 ? (
                <div style={{ opacity: 0, height: 0 }} className="">
                  <div style={{ opacity: 0, height: 0 }} className="">
                    <div ref={targetRef}>
                      <div>
                        <div
                          dangerouslySetInnerHTML={{
                            __html: item.bankLetterHead,
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                " "
              )}
            </>
          );
        }
      })}
    </>
  );
};

export default BankMasterTable;
