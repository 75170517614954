import React, { useState, useRef, useEffect } from "react";
import moment from "moment";
import axios from "axios";
import { APIURL, ViewImageAPI } from "../constant";
import { Storage } from "../login/Storagesetting";
import Modal from "react-bootstrap/Modal";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { FilterMatchMode, FilterService } from "primereact/api";
import "primereact/resources/themes/lara-light-cyan/theme.css";
import { InputText } from "primereact/inputtext";
import "primeicons/primeicons.css";
import { TailSpin } from "react-loader-spinner";
import { Link } from "react-router-dom";
import INSDashboardViewDetails from "../components/INSDashboardViewDetails";
import FINDashboardViewDetails from "../components/FINDashboardViewDetails";
import ImportDashboardViewDetails from "../components/ImportDashboardViewDetails";
import ExportDashboardViewDetails from "../components/ExportDashboardViewDetails";
import ErrorsLogApi from "../components/ErrorslogApi";

const INSCopiedResponseTable = () => {
  const useId = Storage.getItem("userID");
  const rollId = Storage.getItem("roleIDs");
  const bankID = Storage.getItem("bankID");
  const {ErrorlogData} = ErrorsLogApi();

  const [INSCopiedResponse, setINSCopiedResponse] = useState([]);
  const [applicationDetail, setApplicationDetail] = useState({});
  const [tatHistory, setTatHistory] = useState([]);
  const [allcomment, setallcomment] = useState([]);
  const [Actiondata, setActiondata] = useState([]);
  const [noDataComment, setNoDataComment] = useState([]);
  const [loader, setLoader] = useState("");
  const [applicationmessage, setApplicationmessage] = useState("");
  const [responceCount, setresponceCount] = useState([]);
  const [DepID, setDepID] = useState("");
  const [ApplicationReference, setApplicationReference] = useState("");
  const [ApplicationID, setApplicationID] = useState("");
  const [showUpdateModal, setShowUpdateModal] = useState(false);
  const [showUpdateModalIMP, setShowUpdateModalIMP] = useState(false);
  const [showUpdateModalFIN, setShowUpdateModalFIN] = useState(false);
  const [showUpdateModalINS, setShowUpdateModalINS] = useState(false);
  const [showdataLoader, setshowdataloader] = useState(false)
  const [pageLoader, setPageLoader] = useState(false);

  const handleFormClosex = () => setShowUpdateModal(false);

  FilterService.register("custom_activity", (value, filters) => {
    const [from, to] = filters ?? [null, null];
    if (from === null && to === null) return true;
    if (from !== null && to === null) return from <= value;
    if (from === null && to !== null) return value <= to;
    return from <= value && value <= to;
  });

  const [filters, setFilters] = useState({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
    companyName: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
    bankName: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
    tinNumber: { value: null, matchMode: FilterMatchMode.IN },
  });

  const [globalFilterValue, setGlobalFilterValue] = useState("");

  const onGlobalFilterChange = (e) => {
    const value = e.target.value;
    let _filters = { ...filters };
    _filters["global"].value = value;
    setFilters(_filters);
    setGlobalFilterValue(value);
  };

  const renderHeader = () => {
    return (
      <div className="flex justify-content-end">
        <span className="p-input-icon-left">
          <i className="pi pi-search" />
          <InputText
            value={globalFilterValue}
            onChange={onGlobalFilterChange}
            placeholder="Search"
          />
        </span>
      </div>
    );
  };

  const action = (rowData) => {
    return (
      <>
        <div className="d-flex align-items-center">
          <i
            className="pi pi-eye"
            style={{ padding: "12px", cursor: "pointer" }}
            onClick={() => {
              setDepID(rowData?.departmentID)
              setApplicationReference(rowData?.rbzReferenceNumber)
              setApplicationID(rowData?.id)
              // handleViewData(rowData.id);
              // GetHandelDetail(rowData?.rbzReferenceNumber, rowData.id);
              // GetApplicationCount(rowData.id);
            }}
            onMouseEnter={(e) => {
              e.target.style.color = "var(--primary-color)";
            }}
            onMouseLeave={(e) => {
              e.target.style.color = "";
            }}
          ></i>
          {loader == rowData?.id ? (
            <TailSpin
              visible={true}
              height="20"
              width="20"
              color="#5e62a1"
              ariaLabel="tail-spin-loading"
              radius="1"
              wrapperStyle={{}}
              wrapperClass=""
            />
          ) : rowData?.filePath != null ? (
            <Link
              style={{ color: "#4b5563" }}
              target="_blank"
              to={ViewImageAPI + rowData?.filePath}
            >
              <i
                className="pi pi-download p-2 nav-link"
                style={{ padding: "12px", cursor: "pointer" }}
              ></i>
            </Link>
          ) : (
            ""
          )}
        </div>
      </>
    );
  };

  const applicantName = (rowData) => {
    return (
      <>
        {rowData.applicantType === 1 ? (
          <span>
            <i className="bi bi-c-circle-fill text-primary"></i>
            &nbsp;&nbsp;{rowData.companyName}
          </span>
        ) : rowData.applicantType === 2 ? (
          <span>
            <i className="bi bi-person-circle"></i>
            &nbsp;&nbsp;{rowData.name}
          </span>
        ) : rowData.applicantType === 3 || rowData.userTypeID == 3 ? (
          <span>
            <i class="bi bi-bank"></i>
            &nbsp;&nbsp;{rowData.governmentAgencyName}
          </span>
        ) : (
          ""
        )}
      </>
    );
  };

  const receivedDate = (rowData) => {
    return (
      <span>
        {rowData.receivedDate
          ? moment(rowData.receivedDate).format("DD MMM YYYY")
          : "--"}
      </span>
    );
  };

  const submittedDate = (rowData) => {
    return (
      <span>
        {moment(rowData.applicationSubmittedDate).format("DD MMM YYYY")}
      </span>
    );
  };

  const amountwithCurrency = (rowData) => {
    return (
      <span>
        {rowData.currencyCode}&nbsp;{rowData.amount?.toLocaleString()}
      </span>
    );
  };

  const header = renderHeader();

  // ----- Start Code For Geting Table List Data
  const [tabCount, setTabCount] = useState("");
  const handleTabCount = async () => {
    const DataJns = {
      UserID: useId.replace(/"/g, ""),
      RoleID: rollId,
      MenuName: "Approved",
      BankID: bankID,
    }
    await axios
      .post(APIURL + "ExportApplication/CountData", DataJns)
      .then((res) => {
        if (res.data.responseCode === "200") {
          setTabCount(res.data.responseData);
        }
      }).catch((err)=>{
        const api = "ExportApplication/CountData"
        ErrorlogData(DataJns ,err, api )
      });
  };

  // ----- Start Code For Geting Table List Data
  const handleData = async () => {
    setPageLoader(true);
    if (rollId >= 5 && rollId <= 9) {
      await axios
        .post(APIURL + "User/GetCCToDepartmentData", {
          DepartmentID: "5"
        })
        .then((res) => {
          if (res.data.responseCode === "200") {
            setPageLoader(false);
            setINSCopiedResponse(res.data.responseData);

          } else {
            setPageLoader(false);
            setINSCopiedResponse([]);
          }
        }).catch((err)=>{
          const DataJsn = {DepartmentID: "5"}
          const api = "User/GetCCToDepartmentData"
          ErrorlogData(DataJsn ,err, api )
        });
    } else {
      const DataJs = {
        UserID: useId.replace(/"/g, ""),
        Status: "10",
        RoleID: rollId,
        DataType: "All",
        BankID: bankID,
      }
      await axios
        .post(
          APIURL +
          "InspectorateApplication/GetCopiedResApplicationDataByUserIDINS", DataJs
        )
        .then((res) => {
          if (res.data.responseCode === "200") {
            setPageLoader(false);
            setINSCopiedResponse(res.data.responseData);
          } else {
            setPageLoader(false);
            setINSCopiedResponse([]);
          }
        }).catch((err)=>{ 
          const api = "InspectorateApplication/GetCopiedResApplicationDataByUserIDINS"
          ErrorlogData(DataJs ,err, api )
        });
    }
  };
  // ----- End Code For Geting Table List Data

  // ----- Start Code For Open Poup
  const handleViewData = (DepID) => {
    if (DepID == 2) {
      setShowUpdateModal(true);
    } else if (DepID == 3) {
      setShowUpdateModalIMP(true)
    } else if (DepID == 4) {
      setShowUpdateModalFIN(true)
    } else if (DepID == 5) {
      setShowUpdateModalINS(true)
    }
  };

  const handleFormClose = () => {
    if (showUpdateModal == true) {
      setShowUpdateModal(false);
    } else if (showUpdateModalIMP == true) {
      setShowUpdateModalIMP(false)
    } else if (showUpdateModalFIN == true) {
      setShowUpdateModalFIN(false)
    } else if (showUpdateModalINS == true) {
      setShowUpdateModalINS(false)
    }
    // start clear variables
    setDepID("");
    setApplicationID("");
    setApplicationReference("");
    setShowUpdateModalINS(false);
    setShowUpdateModalFIN(false);
    setShowUpdateModalIMP(false);
    setShowUpdateModal(false);
    setApplicationDetail({});
    setPageLoader(false)
    setresponceCount([]);
    setNoDataComment([]);
    setActiondata([]);
    setTatHistory([]);
    setallcomment([]);
    setTabCount("");
    // end clear variables
  };

  useEffect(() => { GetApplicationCount(ApplicationID); GetHandelDetail(ApplicationReference, ApplicationID); handleViewData(DepID) }, [DepID, ApplicationID, ApplicationReference])

  // start application view api
  const GetHandelDetail = async (rbzrefnumber, id) => {
    // start export application view api
    if (DepID == 2) {
      setshowdataloader(true)
      await axios
        .post(APIURL + "ExportApplication/GetRequestInfoByApplicationID", {
          RBZReferenceNumber: `${rbzrefnumber}`,
          ID: id,
        })
        .then((res) => {
          if (res.data.responseCode === "200") {
            setApplicationDetail(res.data.responseData);
            setshowdataloader(false)
          } else {
            setApplicationmessage(res.data.responseMessage);
            setshowdataloader(false)
          }
        })
        .catch((err) => {
          setshowdataloader(false)
          console.log(err);
          const Datajnson = {
            RBZReferenceNumber: `${rbzrefnumber}`,
            ID: id,
          }
          const api = "ExportApplication/GetRequestInfoByApplicationID"
          ErrorlogData(Datajnson ,err, api )
        });

      await axios
        .post(APIURL + "ExportApplication/GetNewComments", {
          ID: id,
        })
        .then((res) => {
          if (res.data.responseCode == 200) {
            setallcomment(res.data.responseData);
            setshowdataloader(false)
          } else {
            setallcomment([]);
            setshowdataloader(false)
          }
        })
        .catch((err) => {
          setshowdataloader(false)
          console.log(err);
          const Datajsn = {ID: id}
          const api = "ExportApplication/GetNewComments"
          ErrorlogData(Datajsn ,err, api )
        });

      await axios
        .post(APIURL + "ExportApplication/GetApplicationHistory", {
          ID: id,
        })
        .then((res) => {
          if (res.data.responseCode == 200) {
            setTatHistory(res.data.responseData);
            setshowdataloader(false)
          } else {
            setTatHistory([]);
            setshowdataloader(false)
          }
        })
        .catch((err) => {
          setshowdataloader(false)
          console.log(err);
          const Datajsn = {ID: id}
          const api = "ExportApplication/GetApplicationHistory"
          ErrorlogData(Datajsn ,err, api )
        });

      await axios
        .post(APIURL + "ExportApplication/GetApplicationActionsByApplicationID", {
          ID: id,
        })
        .then((res) => {
          if (res.data.responseCode == 200) {
            setActiondata(res.data.responseData);
            setshowdataloader(false)
          } else {
            setActiondata([]);
            setshowdataloader(false)
          }
        })
        .catch((err) => {
          console.log(err);
          setshowdataloader(false)
          const Datajsn = {ID: id}
          const api = "ExportApplication/GetApplicationActionsByApplicationID"
          ErrorlogData(Datajsn ,err, api )
        });

      await axios
        .post(APIURL + "ExportApplication/GetCommentsInfoByRoleID", {
          ApplicationID: id,
        })
        .then((res) => {
          if (res.data.responseCode == 200) {
            setNoDataComment(res.data.responseData);
            setshowdataloader(false)
          } else {
            setNoDataComment([]);
            setshowdataloader(false)
          }
        })
        .catch((err) => {
          setshowdataloader(false)
          console.log(err);
          const Datajsn = {ApplicationID: id}
          const api = "ExportApplication/GetCommentsInfoByRoleID"
          ErrorlogData(Datajsn ,err, api )
        });
    }
    // start imp application view api
    if (DepID == 3) {
      setshowdataloader(true)
      await axios
        .post(APIURL + "ImportApplication/GetImportRequestInfoByApplicationID", {
          RBZReferenceNumber: `${rbzrefnumber}`,
          ID: id,
        })
        .then((res) => {
          if (res.data.responseCode === "200") {
            setApplicationDetail(res.data.responseData);
            setshowdataloader(false)
          } else {
            setApplicationmessage(res.data.responseMessage);
            setshowdataloader(false)
          }
        })
        .catch((err) => {
          console.log(err);
          setshowdataloader(false)
          const Datajsn = { RBZReferenceNumber: `${rbzrefnumber}`,
          ID: id}
          const api = "ImportApplication/GetImportRequestInfoByApplicationID"
          ErrorlogData(Datajsn ,err, api )
        });

      await axios
        .post(APIURL + "ImportApplication/GetNewCommentsImport", {
          ID: id,
        })
        .then((res) => {
          if (res.data.responseCode == 200) {
            setallcomment(res.data.responseData);
            setshowdataloader(false)
          } else {
            setallcomment([]);
            setshowdataloader(false)
          }
        })
        .catch((err) => {
          console.log(err);
          setshowdataloader(false)
          const Datajsn = { ID: id}
          const api = "ImportApplication/GetNewCommentsImport"
          ErrorlogData(Datajsn ,err, api )
        });

      await axios
        .post(APIURL + "ImportApplication/GetImportApplicationHistory", {
          ID: id,
          RoleID: rollId
        })
        .then((res) => {
          if (res.data.responseCode == 200) {
            setTatHistory(res.data.responseData);
            setshowdataloader(false)
          } else {
            setTatHistory([]);
            setshowdataloader(false)
          }
        })
        .catch((err) => {
          console.log(err);
          setshowdataloader(false)
          const Datajsn = { ID: id,  RoleID: rollId}
          const api = "ImportApplication/GetImportApplicationHistory"
          ErrorlogData(Datajsn ,err, api )
        });

      await axios
        .post(APIURL + "ImportApplication/GetActionsByApplicationID", {
          ID: id,
        })
        .then((res) => {
          if (res.data.responseCode == 200) {
            setActiondata(res.data.responseData);
            setshowdataloader(false)
          } else {
            setActiondata([]);
            setshowdataloader(false)
          }
        })
        .catch((err) => {
          console.log(err);
          setshowdataloader(false)
          const Datajsn = { ID: id}
          const api = "ImportApplication/GetActionsByApplicationID"
          ErrorlogData(Datajsn ,err, api )
        });

      await axios
        .post(APIURL + "ImportApplication/GetImportCommentsInfoByRoleID", {
          ApplicationID: id,
        })
        .then((res) => {
          if (res.data.responseCode == 200) {
            setNoDataComment(res.data.responseData);
            setshowdataloader(false)
          } else {
            setNoDataComment([]);
            setshowdataloader(false)
          }
        })
        .catch((err) => {
          console.log(err);
          setshowdataloader(false)
          const Datajsn = {  ApplicationID: id}
          const api = "ImportApplication/GetImportCommentsInfoByRoleID"
          ErrorlogData(Datajsn ,err, api )
        });
    }
    // start fin application view api
    if (DepID == 4) {
      setshowdataloader(true)
      await axios
        .post(APIURL + "FINApplication/GetFINRequestInfoByApplicationID", {
          RBZReferenceNumber: `${rbzrefnumber}`,
          ID: id,
        })
        .then((res) => {
          if (res.data.responseCode === "200") {
            setApplicationDetail(res.data.responseData);
            setshowdataloader(false)
          } else {
            setApplicationmessage(res.data.responseMessage);
            setshowdataloader(false)
          }
        })
        .catch((err) => {
          console.log(err);
          setshowdataloader(false)
          const Datajsn = {  RBZReferenceNumber: `${rbzrefnumber}`,
          ID: id,}
          const api = "FINApplication/GetFINRequestInfoByApplicationID"
          ErrorlogData(Datajsn ,err, api )
        });

      await axios
        .post(APIURL + "FINApplication/GetFINNewComments", {
          ID: id,
        })
        .then((res) => {
          if (res.data.responseCode == 200) {
            setallcomment(res.data.responseData);
            setshowdataloader(false)
          } else {
            setallcomment([]);
            setshowdataloader(false)
          }
        })
        .catch((err) => {
          console.log(err);
          setshowdataloader(false)
          const Datajsn = { ID: id}
          const api = "FINApplication/GetFINNewComments"
          ErrorlogData(Datajsn ,err, api )
        });

      await axios
        .post(APIURL + "FINApplication/GetFINApplicationHistory", {
          ID: id,
          RoleID: rollId
        })
        .then((res) => {
          if (res.data.responseCode == 200) {
            setTatHistory(res.data.responseData);
            setshowdataloader(false)
          } else {
            setTatHistory([]);
            setshowdataloader(false)
          }
        })
        .catch((err) => {
          console.log(err);
          setshowdataloader(false)
          const Datajsn = { ID: id, RoleID: rollId}
          const api = "FINApplication/GetFINApplicationHistory"
          ErrorlogData(Datajsn ,err, api )
        });

      await axios
        .post(APIURL + "FINApplication/GetFINApplicationActionsByApplicationID", {
          ID: id,
        })
        .then((res) => {
          if (res.data.responseCode == 200) {
            setActiondata(res.data.responseData);
            setshowdataloader(false)
          } else {
            setActiondata([]);
            setshowdataloader(false)
          }
        })
        .catch((err) => {
          console.log(err);
          setshowdataloader(false)
          const Datajsn = { ID: id}
          const api = "FINApplication/GetFINApplicationActionsByApplicationID"
          ErrorlogData(Datajsn ,err, api )
        });

      await axios
        .post(APIURL + "FINApplication/GetFINCommentsInfoByRoleID", {
          ApplicationID: id,
        })
        .then((res) => {
          if (res.data.responseCode == 200) {
            setNoDataComment(res.data.responseData);
            setshowdataloader(false)
          } else {
            setNoDataComment([]);
            setshowdataloader(false)
          }
        })
        .catch((err) => {
          console.log(err);
          setshowdataloader(false)
          const Datajsn = { ApplicationID: id}
          const api = "FINApplication/GetFINCommentsInfoByRoleID"
          ErrorlogData(Datajsn ,err, api )
        });
    }
    // start ins application view api
    if (DepID == 5) {
      setshowdataloader(true)
      await axios
        .post(
          APIURL + "InspectorateApplication/GetINSRequestInfoByApplicationID",
          {
            RBZReferenceNumber: `${rbzrefnumber}`,
            ID: id,
          }
        )
        .then((res) => {
          if (res.data.responseCode === "200") {
            setApplicationDetail(res.data.responseData);
            setshowdataloader(false)
          } else {
            setApplicationmessage(res.data.responseMessage);
            setshowdataloader(false)
          }
        })
        .catch((err) => {
          console.log(err);
          setshowdataloader(false)
          const Datajsn = {  RBZReferenceNumber: `${rbzrefnumber}`,  ID: id}
          const api = "InspectorateApplication/GetINSRequestInfoByApplicationID"
          ErrorlogData(Datajsn ,err, api )
        });

      await axios
        .post(APIURL + "InspectorateApplication/GetNewCommentsINS", {
          ID: id,
        })
        .then((res) => {
          if (res.data.responseCode == 200) {
            setallcomment(res.data.responseData);
            setshowdataloader(false)
          } else {
            setallcomment([]);
            setshowdataloader(false)
          }
        })
        .catch((err) => {
          console.log(err);
          setshowdataloader(false)
          const Datajsn = { ID: id}
          const api = "InspectorateApplication/GetNewCommentsINS"
          ErrorlogData(Datajsn ,err, api )
        });

      await axios
        .post(APIURL + "InspectorateApplication/GetINSApplicationHistory", {
          ID: id,
          RoleID: rollId
        })
        .then((res) => {
          if (res.data.responseCode == 200) {
            setTatHistory(res.data.responseData);
            setshowdataloader(false)
          } else {
            setTatHistory([]);
            setshowdataloader(false)
          }
        })
        .catch((err) => {
          console.log(err);
          setshowdataloader(false)
          const Datajsn = {  ID: id,   RoleID: rollId}
          const api = "InspectorateApplication/GetINSApplicationHistory"
          ErrorlogData(Datajsn ,err, api )
        });

      await axios
        .post(APIURL + "InspectorateApplication/GetINSApplicationActionsByApplicationID", {
          ID: id,
        })
        .then((res) => {
          if (res.data.responseCode == 200) {
            setActiondata(res.data.responseData);
            setshowdataloader(false)
          } else {
            setActiondata([]);
            setshowdataloader(false)
          }
        })
        .catch((err) => {
          console.log(err);
          setshowdataloader(false)
          const Datajsn = {  ID: id}
          const api = "InspectorateApplication/GetINSApplicationActionsByApplicationID"
          ErrorlogData(Datajsn ,err, api )
        });

      await axios
        .post(APIURL + "InspectorateApplication/GetINSCommentsInfoByRoleID", {
          ApplicationID: id,
        })
        .then((res) => {
          if (res.data.responseCode == 200) {
            setNoDataComment(res.data.responseData);
            setshowdataloader(false)
          } else {
            setNoDataComment([]);
            setshowdataloader(false)
          }
        })
        .catch((err) => {
          console.log(err);
          setshowdataloader(false)
          const Datajsn = {  ApplicationID: id}
          const api = "InspectorateApplication/GetINSCommentsInfoByRoleID"
          ErrorlogData(Datajsn ,err, api )
        });
    }
  };

  const GetApplicationCount = async (id) => {
    // start export application view api
    if (DepID == 2) {
      setshowdataloader(true)
      await axios
        .post(APIURL + "ExportApplication/CountByApplicationID", {
          ApplicationID: id,
        })
        .then((res) => {
          if (res.data.responseCode == 200) {
            setresponceCount(res.data.responseData);
            setshowdataloader(false)
          } else {
            setresponceCount([]);
            setshowdataloader(false)
          }
        })
        .catch((err) => {
          setshowdataloader(false)
          console.log(err);

          const Datajsn = {  ApplicationID: id}
          const api = "ExportApplication/CountByApplicationID"
          ErrorlogData(Datajsn ,err, api )
        });
    }
    // start imp application view api
    if (DepID == 3) {
      setshowdataloader(true)
      await axios
        .post(APIURL + "ExportApplication/CountByApplicationID", {
          ApplicationID: id,
        })
        .then((res) => {
          if (res.data.responseCode == 200) {
            setresponceCount(res.data.responseData);
            setshowdataloader(false)
          } else {
            setresponceCount([]);
            setshowdataloader(false)
          }
        })
        .catch((err) => {
          console.log(err);
          setshowdataloader(false)

          const Datajsn = {  ApplicationID: id}
          const api = "ExportApplication/CountByApplicationID"
          ErrorlogData(Datajsn ,err, api )
        });
    }
    // start fin application view api
    if (DepID == 4) {
      setshowdataloader(true)
      await axios
        .post(APIURL + "FINApplication/CountByApplicationIDFIN", {
          ApplicationID: id,
        })
        .then((res) => {
          if (res.data.responseCode == 200) {
            setresponceCount(res.data.responseData);
            setshowdataloader(false)
          } else {
            setresponceCount([]);
            setshowdataloader(false)
          }
        })
        .catch((err) => {
          console.log(err);
          setshowdataloader(false)
          const Datajsn = {  ApplicationID: id}
          const api = "FINApplication/CountByApplicationIDFIN"
          ErrorlogData(Datajsn ,err, api )
        });
    }
    // start ins application view api
    if (DepID == 5) {
      setshowdataloader(true)
      await axios
        .post(APIURL + "InspectorateApplication/CountByApplicationIDINS", {
          ApplicationID: id,
        })
        .then((res) => {
          if (res.data.responseCode == 200) {
            setresponceCount(res.data.responseData);
            setshowdataloader(false)
          } else {
            setresponceCount([]);
            setshowdataloader(false)
          }
        })
        .catch((err) => {
          console.log(err);
          setshowdataloader(false)

          const Datajsn = {  ApplicationID: id}
          const api = "InspectorateApplication/CountByApplicationIDINS"
          ErrorlogData(Datajsn ,err, api )
        });
    }
  };

  useEffect(() => {
    handleData();
    handleTabCount();
    setINSCopiedResponse([]);
  }, []);

  return (
    <>
      {pageLoader === true ? (
        <label className="outerloader2">
          <span className="loader"></span>
          <span className="loaderwait">Please Wait...</span>
        </label>
      ) : (
        <>
          <div>
            <DataTable
              value={INSCopiedResponse}
              scrollable
              scrollHeight="600px"
              className="mt-4"
              paginator={INSCopiedResponse.length > 10 ? true : false}
              filters={filters}
              paginatorPosition={"both"}
              paginatorLeft
              rowHover
              rows={10}
              dataKey="id"
              rowsPerPageOptions={[10, 50, 100]}
              globalFilterFields={[
                "rbzReferenceNumber",
                "name",
                "companyName",
                "applicationType",
                "amount",
                "statusName",
              ]}
              emptyMessage="No Data found."
              header={header}
            >
              <Column
                field="rbzReferenceNumber"
                header="RBZ Reference Number"
                sortable
                style={{ width: "220px" }}
              ></Column>
              <Column
                field="companyName"
                header="Applicant Name"
                sortable
                style={{ width: "220px" }}
                body={applicantName}
              ></Column>
              <Column
                field="applicationSubmittedDate"
                header="Submitted Date"
                sortable
                style={{ width: "140px" }}
                body={submittedDate}
              ></Column>
              <Column
                field="receivedDate"
                header="Received Date"
                sortable
                style={
                  rollId == 4
                    ? { width: "140px" }
                    : { display: "none" }
                }
                body={receivedDate}
              ></Column>
              <Column
                field="applicationType"
                header="Application Type"
                sortable
                style={{ width: "200px" }}
              ></Column>
              <Column
                field="amount"
                header="Amount"
                sortable
                body={amountwithCurrency}
                style={{ width: "150px" }}
              ></Column>
              <Column
                field="statusName"
                header="Status"
                sortable
                style={{ width: "200px" }}
              ></Column>
              <Column
                field=""
                header="Action"
                style={{ width: "200px" }}
                frozen
                alignFrozen="right"
                body={action}
              ></Column>
            </DataTable>
          </div>
          {/* Export view modal start */}
          <Modal
            show={showUpdateModal}
            onHide={handleFormClose}
            backdrop="static"
            className="max-width-600"
          >
            <div className="application-box">
              <div className="login_inner">
                <div className="login_form ">
                  <h5>
                    <Modal.Header closeButton className="p-0">
                      <Modal.Title>
                        View Export Request --{" "}
                        <big>{applicationDetail?.rbzReferenceNumber}</big>
                      </Modal.Title>
                    </Modal.Header>
                  </h5>
                </div>
                <div className="login_form_panel">
                  <Modal.Body className="p-0">
                    <ExportDashboardViewDetails
                      applicationDetail={applicationDetail}
                      handleFormClose={handleFormClose}
                      allcomment={allcomment}
                      showdataLoader={showdataLoader}
                      noDataComment={noDataComment}
                      tatHistory={tatHistory}
                      Actiondata={Actiondata}
                      responceCount={responceCount}
                    />
                  </Modal.Body>
                </div>
              </div>
            </div>
          </Modal>
          {/* Export view modal End */}

          {/* Import view modal start */}
          <Modal
            show={showUpdateModalIMP}
            onHide={handleFormClose}
            backdrop="static"
            className="max-width-600"
          >
            <div className="application-box">
              <div className="login_inner">
                <div className="login_form ">
                  <h5>
                    <Modal.Header closeButton className="p-0">
                      <Modal.Title>
                        View Import Request --{" "}
                        <big>{applicationDetail?.rbzReferenceNumber}</big>
                      </Modal.Title>
                    </Modal.Header>
                  </h5>
                </div>
                <div className="login_form_panel">
                  <Modal.Body className="p-0">
                    <ImportDashboardViewDetails
                      applicationDetail={applicationDetail}
                      handleFormClose={handleFormClose}
                      allcomment={allcomment}
                      tatHistory={tatHistory}
                      Actiondata={Actiondata}
                      noDataComment={noDataComment}
                      showdataLoader={showdataLoader}
                      responceCount={responceCount}
                    />
                  </Modal.Body>
                </div>
              </div>
            </div>
          </Modal>
          {/* Import view modal End */}

          {/* FIN view modal start */}
          <Modal
            show={showUpdateModalFIN}
            onHide={handleFormClose}
            backdrop="static"
            className="max-width-600"
          >
            <div className="application-box">
              <div className="login_inner">
                <div className="login_form ">
                  <h5>
                    <Modal.Header closeButton className="p-0">
                      <Modal.Title>
                        View Foreign Investments Request --{" "}
                        <big>{applicationDetail?.rbzReferenceNumber}</big>
                      </Modal.Title>
                    </Modal.Header>
                  </h5>
                </div>
                <div className="login_form_panel">
                  <Modal.Body className="p-0">
                    <FINDashboardViewDetails
                      applicationDetail={applicationDetail}
                      showdataLoader={showdataLoader}
                      handleFormClose={handleFormClose}
                      allcomment={allcomment}
                      noDataComment={noDataComment}
                      tatHistory={tatHistory}
                      Actiondata={Actiondata}
                      responceCount={responceCount}
                    />
                  </Modal.Body>
                </div>
              </div>
            </div>
          </Modal>
          {/* FIN view modal End */}

          {/* INS view modal start */}
          <Modal
            show={showUpdateModalINS}
            onHide={handleFormClose}
            backdrop="static"
            className="max-width-600"
          >
            <div className="application-box">
              <div className="login_inner">
                <div className="login_form ">
                  <h5>
                    <Modal.Header closeButton className="p-0">
                      <Modal.Title>
                        View Financial Surveillance Request --{" "}
                        <big>{applicationDetail?.rbzReferenceNumber}</big>
                      </Modal.Title>
                    </Modal.Header>
                  </h5>
                </div>
                <div className="login_form_panel">
                  <Modal.Body className="p-0">
                    <INSDashboardViewDetails
                      applicationDetail={applicationDetail}
                      showdataLoader={showdataLoader}
                      handleFormClose={handleFormClose}
                      allcomment={allcomment}
                      noDataComment={noDataComment}
                      tatHistory={tatHistory}
                      Actiondata={Actiondata}
                      responceCount={responceCount}
                    />
                  </Modal.Body>
                </div>
              </div>
            </div>
          </Modal>
          {/* INS view modal End */}
        </>
      )}
    </>
  );
};
export default INSCopiedResponseTable;
