import React, { useState, useRef, useEffect } from "react";
import moment from "moment";
import axios from "axios";
import { APIURL } from "../constant";
import { Storage } from "../login/Storagesetting";
import Modal from "react-bootstrap/Modal";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { FilterMatchMode, FilterService } from "primereact/api";
import "primereact/resources/themes/lara-light-cyan/theme.css";
import { InputText } from "primereact/inputtext";
import "primeicons/primeicons.css";
import { TailSpin } from "react-loader-spinner";
import { Paginator } from "primereact/paginator";
import Spinner from "react-bootstrap/Spinner";
import { Link } from "react-router-dom";
import ImportOtherDepartmentViewDetails from "../components/ImportOtherDepartmentViewDetails";
import ImportOtherDepartmentEditDetails from "../components/ImportOtherDepartmentEditDetails";
import ExportOtherDepartmentEditDetails from "../components/ExportOtherDepartmentEditDetails";
import ExportOtherDepartmentViewDetails from "../components/ExportOtherDepartmentViewDetails";
import FINOtherDepartmentViewDetails from "../components/FINOtherDepartmentViewDetails";
import FINOtherDepartmentEditDetails from "../components/FINOtherDepartmentEditDetails";
import INSOtherDepartmentEditDetails from "../components/INSOtherDepartmentEditDetails";
import INSOtherDepartmentViewDetails from "../components/INSOtherDepartmentViewDetails";
import ErrorsLogApi from "../components/ErrorslogApi";

const AllcloseApplicationTable = () => {
  const useId = Storage.getItem("userID");
  const rollId = Storage.getItem("roleIDs");
  const bankID = Storage.getItem("bankID");
  const menuname = Storage.getItem("menuname");
  const {ErrorlogData} = ErrorsLogApi();

  const DeptID =
    menuname === "Exports"
      ? "2"
      : menuname === "Imports"
      ? "3"
      : menuname === "Foreign Investments"
      ? "4"
      : menuname === "Financial Surveillance"
      ? "5"
      : "";

  const [ExportsapproveRequests, setExportsapproveRequests] = useState([]);
  const [showUpdateModal, setShowUpdateModal] = useState(false);
  const [showExportUpdateModal, setExportShowUpdateModal] = useState(false);
  const [showFINUpdateModal, setFINShowUpdateModal] = useState(false);
  const [showINSUpdateModal, setINSShowUpdateModal] = useState(false);
  const [applicationDetail, setApplicationDetail] = useState({});
  const [applicationmessage, setApplicationmessage] = useState("");
  const [tatHistory, setTatHistory] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [allcomment, setallcomment] = useState([]);
  const [noDataComment, setNoDataComment] = useState([]);
  const [userRole, setUserrole] = useState([]);
  const [responceCount, setresponceCount] = useState([]);        
  const [Actiondata, setActiondata] = useState([]);  
  const [pageLoader, setPageLoader] = useState("");
  const [tabDepId, setTabDepId] = useState("");
  const [filters, setFilters] = useState({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
    companyName: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
    bankName: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
    tinNumber: { value: null, matchMode: FilterMatchMode.IN },
  });
  const [loading, setLoading] = useState(false);
  const [globalFilterValue, setGlobalFilterValue] = useState("");

  const closeDepartment = sessionStorage.getItem("closeDepartment")


  const onGlobalFilterChange = (e) => {
    const value = e.target.value;
    let _filters = { ...filters };
    _filters["global"].value = value;
    setFilters(_filters);
    setGlobalFilterValue(value);
  };

  const handleFormClose = () => {
    if (closeDepartment == "2") {
      setExportShowUpdateModal(false);
    } else if (closeDepartment == "3") {
      setShowUpdateModal(false);
    } else if (closeDepartment == "4") {
      setFINShowUpdateModal(false);
    } else if (closeDepartment == "5") {
      setINSShowUpdateModal(false);
    }
  };

  const action = (rowData) => {
    return (
      <>
        <i
          className="pi pi-eye"
          style={{ padding: "12px", cursor: "pointer" }}
          onClick={() => {
            handleViewData(rowData.id);
            GetHandelDetail(rowData?.rbzReferenceNumber, rowData.id);
            GetApplicationCount(rowData.id);
          }}
          onMouseEnter={(e) => {
            e.target.style.color = "var(--primary-color)";
          }}
          onMouseLeave={(e) => {
            e.target.style.color = "";
          }}
        ></i> 
        
      </>
    );
  };
 
     

  const applicantName = (rowData) => {
    return (
      <>
        {rowData.applicantType === 1 ? (
          <span>
            <i className="bi bi-c-circle-fill text-primary"></i>
            &nbsp;&nbsp;{rowData.companyName}
          </span>
        ) : rowData.applicantType === 2 ? (
          <span>
            <i className="bi bi-person-circle"></i>
            &nbsp;&nbsp;{rowData.name}
          </span>
        ) : (
          ""
        )}
      </>
    );
  };

  const amountwithCurrency = (rowData) => {
    return (
      <span>
        {rowData.currencyCode}&nbsp;{rowData.amount?.toLocaleString()}
      </span>
    );
  };

  const submittedDate = (rowData) => {
    return (
      <span>
        {moment(rowData.applicationSubmittedDate).format("DD MMM YYYY")}
      </span>
    );
  };

  const handleData = async () => {
    const Datagetcloseref = {
      UserID: useId.replace(/"/g, ""),
      RoleID: rollId,
      DepartmentID: sessionStorage.getItem("closeDepartment"),
    }
    setPageLoader(true);
    await axios
      .post(APIURL + "ReferredApplication/GetClosedReferredApplications", Datagetcloseref)
      .then((res) => {
        if (res.data.responseCode === "200") {
          setPageLoader(false);
          setExportsapproveRequests(res.data.responseData);
        } else if (res.data.responseCode === "401") {
          setExportsapproveRequests([]);
          setPageLoader(false);
        }
      }).catch((err) => {
        console.log(err);
        const api = "ReferredApplication/GetClosedReferredApplications"
        ErrorlogData(Datagetcloseref ,err, api )
      });
  };

  useEffect(() => {
    setTabDepId(DeptID == "2" ? "3" : "2");
    handleData();
  }, [DeptID]);

  const handleViewData = (id) => {
    if (closeDepartment == "2") {
      setExportShowUpdateModal(true);
    } else if (closeDepartment == "3") {
      setShowUpdateModal(true);
    } else if (closeDepartment == "4") {
      setFINShowUpdateModal(true);
    } else if (closeDepartment == "5") {
      setINSShowUpdateModal(true);
    }
  };
  

  const GetHandelDetail = async (rbzrefnumber, id) => {
    setLoading(true);
    const DataGetReferred ={
      RBZReferenceNumber: `${rbzrefnumber}`,
      ID: id,
    }
    await axios
      .post(APIURL + "ReferredApplication/GetReferredInfoByApplicationID", DataGetReferred)
      .then((res) => {
        if (res.data.responseCode === "200") {
          setLoading(false);
          setApplicationDetail(res.data.responseData);
        } else {
          setLoading(false);
          setApplicationmessage(res.data.responseMessage);
        }
      })
      .catch((err) => {
        console.log(err);
        const api = "ReferredApplication/GetReferredInfoByApplicationID"
        ErrorlogData(DataGetReferred ,err, api )
      });

      const GetnewComm ={
        ID: id,
      }
    await axios
      .post(APIURL + "ReferredApplication/GetNewCommentsReferred", GetnewComm )
      .then((res) => {
        if (res.data.responseCode == 200) {
          setallcomment(res.data.responseData);
        } else {
          setallcomment([]);
        }
      })
      .catch((err) => {
        console.log(err);
        const api = "ReferredApplication/GetNewCommentsReferred"
        ErrorlogData(GetnewComm ,err, api )
      });

      const DatagetRef ={
        ID: id,
      }
    await axios
      .post(APIURL + "ReferredApplication/GetReferredApplicationHistory", DatagetRef)
      .then((res) => {
        if (res.data.responseCode == 200) {
          setTatHistory(res.data.responseData);
        } else {
          setTatHistory([]);
        }
      })
      .catch((err) => {
        console.log(err);
        const api = "eferredApplication/GetReferredApplicationHistory"
        ErrorlogData(DatagetRef ,err, api )
      });

      const GetreferredId ={
        ID: id,
      }
    await axios
      .post(APIURL + "ReferredApplication/GetReferredActionsByApplicationID",GetreferredId )
      .then((res) => {
        if (res.data.responseCode == 200) {
          setActiondata(res.data.responseData);
        } else {
          setActiondata([]);
        }
      })
      .catch((err) => {
        console.log(err);
        const api = "ReferredApplication/GetReferredActionsByApplicationID"
        ErrorlogData(GetreferredId ,err, api )
      });

    // --------------------------vishwas start----------------------------

    const DataGetComm = {
      ApplicationID: id,
    }
    await axios
      .post(APIURL + "ReferredApplication/GetReferredCommentsInfoByRoleID", DataGetComm)
      .then((res) => {
        if (res.data.responseCode == 200) {
          setNoDataComment(res.data.responseData);
        } else {
          setNoDataComment([]);
        }
      })
      .catch((err) => {
        console.log(err);
        const api = "ReferredApplication/GetReferredCommentsInfoByRoleID"
        ErrorlogData(DataGetComm ,err, api )
      });
    //---------------------------vishwas end------------------------------
  };

  const GetApplicationCount = async (id) => {
    const DataAppcount ={
      ApplicationID: id,
    }
    await axios
      .post(APIURL + "ReferredApplication/CountByApplicationIDReferred",DataAppcount )
      .then((res) => {
        if (res.data.responseCode == 200) {
          setresponceCount(res.data.responseData);
        } else {
          setresponceCount([]);
        }
      })
      .catch((err) => {
        console.log(err);
        const api = "ReferredApplication/CountByApplicationIDReferred"
        ErrorlogData(DataAppcount ,err, api )
      });
  };
 

  const renderHeader = () => {
    return (
      <div className="d-flex align-items-center justify-content-between">
        <span className="p-input-icon-left">
          <i className="pi pi-search" />
          <InputText
            value={globalFilterValue}
            onChange={onGlobalFilterChange}
            placeholder="Search"
          />
        </span>
      </div>
    );
  };


  const header = renderHeader();

  useEffect(() => {
    handleData();
    setExportsapproveRequests([]);
  }, [tabDepId]);

  return (
    <> 
      {pageLoader == true ? (
        <label className="outerloader2">
          <span className="loader"></span>
          <span className="loaderwait">Please Wait...</span>
        </label>
      ) : ExportsapproveRequests?.length == 0 ||
        ExportsapproveRequests?.length == 0 ? (
        <div className="p-3">No records to show</div>
      ) : (
        <>
          {loading == true ? (
            <label className="outerloader2">
              <span className="loader"></span>
              <span className="loaderwait">Please Wait...</span>
            </label>
          ) : (
            <>
              <div className="pagination-top pagination-top-right"></div>
              <div className="clear"></div>
              <DataTable
                value={ExportsapproveRequests}
                scrollable
                scrollHeight="600px"
                rowHover
                filters={filters}
                paginator={ExportsapproveRequests?.length > 10 ? true : false}
                paginatorPosition={"both"}
                paginatorLeft
                rows={10}
                dataKey="id"
                rowsPerPageOptions={[10, 50, 100]}
                globalFilterFields={[
                  "rbzReferenceNumber",
                  "name",
                  "companyName",
                  "applicationType",
                  "amount",
                  "statusName",
                ]}
                emptyMessage="No Data found."
                header={header}
              >
                <Column
                  field="rbzReferenceNumber"
                  header="RBZ Reference Number"
                  sortable
                  style={{ width: "220px" }}
                ></Column>
                <Column
                  field="companyName"
                  header="Applicant Name"
                  sortable
                  style={{ width: "220px" }}
                  body={applicantName}
                ></Column>
                <Column
                  field="applicationSubmittedDate"
                  header="Submitted Date"
                  sortable
                  style={{ width: "140px" }}
                  body={submittedDate}
                ></Column>
                <Column
                  field="applicationType"
                  header="Application Type"
                  sortable
                  style={{ width: "200px" }}
                ></Column>
                <Column
                  field="amount"
                  header="Amount"
                  sortable
                  body={amountwithCurrency}
                  style={{ width: "150px" }}
                ></Column>
                <Column
                  field="statusName"
                  header="Status"
                  sortable
                  style={{ width: "200px" }}
                ></Column>
                <Column
                  field=""
                  header="Action"
                  style={{ width: "200px" }}
                  frozen
                  alignFrozen="right"
                  body={action}
                ></Column>
              </DataTable>
            </>
          )}

          {/* Import View and Edit popup start */}
          <Modal
            show={showUpdateModal}
            onHide={handleFormClose}
            backdrop="static"
            className="max-width-600"
          >
            <div className="application-box">
              <div className="login_inner">
                <div className="login_form ">
                  <h5>
                    <Modal.Header closeButton className="p-0">
                      <Modal.Title>
                        View Import Closed Request --{" "}
                        <big>{applicationDetail?.rbzReferenceNumber}</big>
                      </Modal.Title>
                    </Modal.Header>
                  </h5>
                </div>
                <div className="login_form_panel">
                  <Modal.Body className="p-0">
                    <ImportOtherDepartmentViewDetails
                      applicationDetail={applicationDetail}
                      handleFormClose={handleFormClose}
                      allcomment={allcomment}
                      tatHistory={tatHistory}
                      noDataComment={noDataComment}
                      responceCount={responceCount}
                    />
                  </Modal.Body>
                </div>
              </div>
            </div>
          </Modal>
         
          {/* Import View and Edit popup end */}

          {/* Export View and Edit popup start */}
          <Modal
            show={showExportUpdateModal}
            onHide={handleFormClose}
            backdrop="static"
            className="max-width-600"
          >
            <div className="application-box">
              <div className="login_inner">
                <div className="login_form ">
                  <h5>
                    <Modal.Header closeButton className="p-0">
                      <Modal.Title>
                        View Export Closed Request --{" "}
                        <big>{applicationDetail?.rbzReferenceNumber}</big>
                      </Modal.Title>
                    </Modal.Header>
                  </h5>
                </div>
                <div className="login_form_panel">
                  <Modal.Body className="p-0">
                    <ExportOtherDepartmentViewDetails
                      applicationDetail={applicationDetail}
                      handleFormClose={handleFormClose}
                      allcomment={allcomment}
                      tatHistory={tatHistory}
                      noDataComment={noDataComment}
                      responceCount={responceCount}
                      showdataLoader={loading}
                    />
                  </Modal.Body>
                </div>
              </div>
            </div>
          </Modal>
         
          {/* Export View and Edit popup end */}

          {/* FIN View and Edit popup start */}
          <Modal
            show={showFINUpdateModal}
            onHide={handleFormClose}
            backdrop="static"
            className="max-width-600"
          >
            <div className="application-box">
              <div className="login_inner">
                <div className="login_form ">
                  <h5>
                    <Modal.Header closeButton className="p-0">
                      <Modal.Title>
                        View Foreign Investments Closed Request --{" "}
                        <big>{applicationDetail?.rbzReferenceNumber}</big>
                      </Modal.Title>
                    </Modal.Header>
                  </h5>
                </div>
                <div className="login_form_panel">
                  <Modal.Body className="p-0">
                    <FINOtherDepartmentViewDetails
                      applicationDetail={applicationDetail}
                      handleFormClose={handleFormClose}
                      allcomment={allcomment}
                      tatHistory={tatHistory}
                      noDataComment={noDataComment}
                      responceCount={responceCount}
                      showdataLoader={loading}
                    />
                  </Modal.Body>
                </div>
              </div>
            </div>
          </Modal>
         
          {/* FIN View and Edit popup end */}

          {/* INS View and Edit popup start */}
          <Modal
            show={showINSUpdateModal}
            onHide={handleFormClose}
            backdrop="static"
            className="max-width-600"
          >
            <div className="application-box">
              <div className="login_inner">
                <div className="login_form ">
                  <h5>
                    <Modal.Header closeButton className="p-0">
                      <Modal.Title>
                        View Financial Surveillance Closed Request --{" "}
                        <big>{applicationDetail?.rbzReferenceNumber}</big>
                      </Modal.Title>
                    </Modal.Header>
                  </h5>
                </div>
                <div className="login_form_panel">
                  <Modal.Body className="p-0">
                    <INSOtherDepartmentViewDetails
                      applicationDetail={applicationDetail}
                      handleFormClose={handleFormClose}
                      allcomment={allcomment}
                      tatHistory={tatHistory}
                      noDataComment={noDataComment}
                      responceCount={responceCount}
                      showdataLoader={loading}
                    />
                  </Modal.Body>
                </div>
              </div>
            </div>
          </Modal>
          
          {/* INS View and Edit popup end */}
        </>
      )}
    </>
  );
};
export default AllcloseApplicationTable;
