import React, { useEffect, useState } from "react";
import logo from "../Logo_T.png";
import { Link, useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";
import { Buffer } from 'buffer';
import AuthUser from "./AuthUser";
import { APIURL } from "../constant";
import { toast } from "react-toastify";
import Resetpassword from "./Resetpassword";
import axios from "axios";
import ErrorsLogApi from "../components/ErrorslogApi";
import Menubar from "../navbar/Menubar";

const Login = () => {
  const { ErrorlogData } = ErrorsLogApi();
  const navigation = useNavigate();

  const { http, setToken } = AuthUser();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [checkloader, setCheckloader] = useState(false);
  const [errors, setErrors] = useState({});
  const [Checkpass, setCheckpass] = useState(false);
  const { getMenudata, getMenudatacount } = Menubar();
  const [faillogin, setfaillogin] = useState("");
  const [failstatus, setfailstatus] = useState("");
  const [showUpdateModal, setShowUpdateModal] = useState(false);

  // form validation check
  const validateForm = () => {
    let valid = true;
    const newErrors = {};
    if (email === "") {
      newErrors.email = "Username is required";
      valid = false;
    }
    if (password === "") {
      newErrors.password = "Password is required";
      valid = false;
    }
    setErrors(newErrors);
    return valid;
  };

  useEffect(() => {
    document.addEventListener('contextmenu', (event) => {
      event.preventDefault();
    });
    return () => {
      document.removeEventListener('contextmenu', (event) => {
        event.preventDefault();
      });
    };
  }, []);

  useEffect(() => {
    const handleKeyDown = (e) => {
      // Prevent default behavior for specific key combinations
      if (e.ctrlKey && e.shiftKey && e.key === 'I') {
        e.preventDefault();
        console.log('Ctrl + Shift + I is disabled');
      }

      if (e.ctrlKey && e.keyCode === 85) {
        e.preventDefault();
        console.log('Ctrl + U is disabled');
      }

      // if (e.ctrlKey && (e.keyCode === 67 || e.keyCode === 86 || e.keyCode === 85 || e.keyCode === 117)) {
      //   e.preventDefault();
      //   console.log('Ctrl + C, Ctrl + V, Ctrl + U, and Ctrl + F6 are disabled');
      // }
    };

    const handleKeyPress = (e) => {
      // Prevent default behavior for Ctrl + U
      if (e.ctrlKey && e.key === 'u') {
        e.preventDefault();
        console.log('Ctrl + U is disabled');
      }
    };

    // Attach event listeners
    document.addEventListener('keydown', handleKeyDown);
    document.addEventListener('keypress', handleKeyPress);

    // Cleanup event listeners on component unmount
    return () => {
      document.removeEventListener('keydown', handleKeyDown);
      document.removeEventListener('keypress', handleKeyPress);
    };
  }, []);


  const HandleEmail = (e) => {
    const name = e.target.name;
    const value = e.target.value.trim();
    setfaillogin("");
    if (
      (name === "email" &&
        (value.includes("$") ||
          value.includes("`") ||
          value.includes("|") ||
          value.includes(" ") ||
          value.includes("~") ||
          value.includes(":") ||
          value.includes(",") ||
          value.includes(">") ||
          value.includes("<") ||
          value.includes("(") ||
          value.includes(")") ||
          value.includes("*") ||
          value.includes("&") ||
          value.includes("%") ||
          value.includes("#") ||
          value.includes("+") ||
          value.includes("?") ||
          value.includes("!") ||
          value.includes(";"))) ||
      value.includes("=") ||
      value.includes('"') ||
      value.includes(`'`) ||
      value.includes("/") ||
      value.includes("}") ||
      value.includes("{") ||
      value.includes("^") ||
      value.includes("\\") ||
      value.includes("]") ||
      value.includes("[")
    ) {
      return setErrors({
        email: `Special character's not allowed except @, _ , - , .`,
      });
    } else {
      setErrors({
        email: ``,
      });
    }

    setEmail(value);
  };

  const HandlePassowrd = (e) => {
    const name = e.target.name;
    const value = e.target.value.trim();
    const passwordValue = e.target.value.trim();
    setfaillogin("");
    if (name === "password" && value.includes(" ")) {
      const errmesg = value.slice(-1);
      const errmesglast = errmesg[errmesg.length - 1];
      return setErrors({
        password: `Space not allowed`,
      });
    } else {
      setErrors({
        password: ``,
      });
    }
    setPassword(value);
  };

  const submitForm = async () => {
    if (validateForm()) {
      setCheckloader(true);
      const DataReferred = {
        userName: email,
        password: new Buffer(password).toString('base64'),
      }
      http
        .post(APIURL + "User/UserLogin", DataReferred)
        .then((res) => {
          if (res.data.responseCode === "200") {
            setToken(
              res.data.responseData.userType,
              res.data.responseData.token,
              res.data.responseData.userID,
              res.data.responseData.userName,
              res.data.responseData.applicantType,
              res.data.responseData.name,
              res.data.responseData.roleID,
              res.data.responseData.roleName,
              res.data.responseData.bankID,
              res.data.responseData.bankName,
              // ipAddress,
              res.data.responseData.signImageURL,
              res.data.responseData.companyID,
              res.data.responseData.companyName,
              res.data.responseData.agencyID,
              res.data.responseData.agencyName,
            );
            getMenudata(
              res.data.responseData.roleID,
              res.data.responseData.loginToken,
              res.data.responseData.userID,
              res.data.responseData.bankID
            );
          } else {
            toast.error(res.data.responseMessage, {
              autoClose: 1000,
            });
            setfaillogin(res.data.responseMessage);
            setfailstatus(res.data.responseStatus);
            setTimeout(() => {
              setCheckloader(false);
            }, 1500);
          }
        })
        .catch((err) => {
          const api = "User/UserLogin"
          ErrorlogData(DataReferred, err, api)
          toast.error(err, {
            autoClose: 1000,
          });
        });
    }
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      submitForm();
    }
  };

  return (
    <>
      <Helmet>
        {" "}
        <title>Login</title>{" "}
      </Helmet>

      <div className="login_outer">
        <div className="login_inner login-max-width">
          <div className="login_inner_header">
            <img src={logo} />
            <h3>Enhanced Document Management System (eDMS)</h3>
            {/* <h3>Document Management System</h3> */}
          </div>

          <div className="login_form ">
            <h5>LOGIN FORM</h5>
          </div>

          <div className="login_form_panel">
            <div className="form-bx mb-5">
              <label>
                <input
                  type="email"
                  name="email"
                  className={errors?.email ? " error" : ""}
                  placeholder="Username"
                  onChange={(e) => {
                    HandleEmail(e);
                  }}
                  value={email.trim()}
                  id="email"
                  onKeyDown={handleKeyDown}
                  disabled={checkloader === true ? true : false}
                />

                <span className="sspan"></span>
              </label>
              {errors?.email ? (
                <small className="errormsg">{errors?.email}</small>
              ) : failstatus === "1" ? (
                <small className="errormsg">{faillogin}</small>
              ) : (
                ""
              )}
            </div>
            <div className="form-bx">
              <label>
                <input
                  name="password"
                  type={Checkpass === true ? "text" : "password"}
                  className={errors?.password ? " error" : ""}
                  placeholder="Password"
                  onChange={(e) => {
                    HandlePassowrd(e);
                  }}
                  id="pwd"
                  onKeyDown={handleKeyDown}
                  value={password}
                  disabled={checkloader === true ? true : false}
                />
                <span className="sspan"></span>
              </label>
              {errors?.password ? (
                <small className="errormsg2">{errors?.password}</small>
              ) : failstatus === "2" ? (
                <small className="errormsg">{faillogin}</small>
              ) : (
                ""
              )}
              {Checkpass ? (
                <i
                  className="bi bi-eye passwordcheck"
                  onClick={() => {
                    setCheckpass(!Checkpass);
                  }}
                ></i>
              ) : (
                <i
                  className="bi bi-eye-slash passwordcheck"
                  onClick={() => {
                    setCheckpass(!Checkpass);
                  }}
                ></i>
              )}
            </div>

            <div className="form-bx mt-5">
              <Link onClick={() => setShowUpdateModal(true)}>
                Forgot password?
              </Link>
            </div>

            <div className="form-footer">
              <Link to="/usertype" className="register">
                Register
              </Link>
              <button
                className="login"
                type="button"
                onClick={(e) => submitForm(e)}
                disabled={checkloader === true ? true : false}
                onKeyDown={handleKeyDown}
              >
                {checkloader === true ? "Please Wait.." : "Login"}
              </button>
            </div>
          </div>
        </div>
      </div>

      <Resetpassword
        setShowUpdateModal={setShowUpdateModal}
        showUpdateModal={showUpdateModal}
      />
    </>
  );
};

export default Login;
