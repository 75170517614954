import React, { useState, useRef, useEffect } from "react";
import DataTable from "react-data-table-component";
import { CSVLink } from "react-csv";
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
import ApplicationTypeForm from "../components/ApplicationTypeForm";
import { Link } from "react-router-dom";
import ErrorsLogApi from "../components/ErrorsLogApi";
import { APIURL } from "../../constant";
import axios from "axios";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { toast } from "react-toastify";

const ApplicationTypeTable = () => {
  const [searchText, setSearchText] = useState("");
  const [tableData, setTableData] = useState([]);
  const [toastDisplayed, setToastDisplayed] = useState(false);
  const [loader, setLoader] = useState(false);
  const [formerr, setformerr] = useState();
  const [updateerr, setupdateerr] = useState();

  const csvLinkRef = useRef();

  const handleClick = (title) => {
    alert(`Title: ${title}`);
  };

  const [departmentData, setDepartmentData] = useState([]);
  const [attachmentList, setAttachmentList] = useState([]);
  const [attachmentChecked, setAttachmentChecked] = useState([]);
  const [attachmentListLoader, setAttachmentListLoader] = useState(false);
  const { ErrorlogData } = ErrorsLogApi();
  const departmentApi = async () => {
    try {
      const responce = await fetch(APIURL + "Master/GetDepartmentData", {
        method: "Post",
        headers: {
          "content-type": "application/json",
        },
      });
      const data = await responce.json();
      setDepartmentData(data.responseData);
    } catch (error) {
      console.log("Fetch Data", error);
    }
  };

  // applicationtype update start

  const [showUpdateModal, setShowUpdateModal] = useState(false);
  const UpdateModalClose = () => {
    setShowUpdateModal(false);
    setUpdateData({
      name: "",
      departmentID: "",
      status: "",
    });
    setupdateerr("");
  };
  const [updateData, setUpdateData] = useState({
    name: "",
    departmentID: "",
    status: "",
  });
  const changeUpdateForm = (e) => {
    const { name, value } = e.target;

    let newErrors = {};

    const specialCharsOLD = /[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;
    const spaceCheck = /\s{2,}/g;
    // const specialChars = /[^\w\s&,_%-]/;
    const specialChars = /[^\w\s&,-]/;
    if (name == "name" && specialChars.test(value)) {
      newErrors.name = "Special characters not allowed.";
    } else if (name == "name" && value.charAt(0) === " ") {
      newErrors.name = "First character cannot be a blank space.";
    } else if (name == "name" && spaceCheck.test(value)) {
      newErrors.name = "Multiple space not allow.";
    } else if (name == "name" && specialCharsOLD.test(value.charAt(0))) {
      newErrors.name = "First special character is not allowed.";
    } else {
      setUpdateData((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    }
    setupdateerr(newErrors);
  };
  const [updateID, setUpdateID] = useState("");
  const handleUpdate = async (id) => {
    setShowUpdateModal(true);
    setUpdateID(id);
    const TableId = {
      id: id,
    };

    try {
      const response = await fetch(APIURL + "Admin/GetApplicationTypeByID", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(TableId),
      });

      const data = await response.json();
      setUpdateData(data.responseData);
    } catch (error) {
      const api = "Admin/GetApplicationTypeByID";
      ErrorlogData(TableId, error, api);
      console.error("Error fetching data:", error);
    }
  };
  const handleUpdateData = async () => {
    const updateValue = {
      id: updateID,
      Name: updateData.name,
      DepartmentID: updateData.departmentID,
      status: updateData.status,
    };
    try {
      if (isUpdateValid()) {
        const response = await fetch(APIURL + "Admin/UpdateApplicationType", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(updateValue),
        });

        const data = await response.json();
        setToastDisplayed(true);
        if (data.responseCode === "200") {
          toast.success(data.responseMessage, { autoClose: 2000 });
          setTimeout(() => {
            UpdateModalClose();
            table_Data();
            setUpdateData({
              name: "",
              departmentID: "",
            });
            setSearchText("");
            setToastDisplayed(false);
          }, 2500);
        } else {
          toast.warning(data.responseMessage, { autoClose: 2000 });
          setTimeout(() => {
            table_Data();
            setToastDisplayed(false);
          }, 2500);
        }
      }
    } catch (error) {
      const api = "Admin/UpdateApplicationType";
      ErrorlogData(updateValue, error, api);
      console.error("Error fetching data:", error);
    }
  };

  const [tabDepId, setTabDepId] = useState("1");

  const handleClickTag = (id) => {
    setTabDepId(id);
  };
  // applicationtype update end

  // application list with department wise api start
  const table_Data = async () => {
    setLoader(true);
    const deptID = {
      DepartmentID: tabDepId,
    };
    try {
      const response = await fetch(
        APIURL + "Admin/GetAllApplicationTypeByDepartment",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(deptID),
        }
      );
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const data = await response.json();
      if (data.responseCode == "200") {
        setTableData(data.responseData);
        setLoader(false);
      } else if (data.responseMessage == "No Data") {
        setLoader(false);
      }
    } catch (error) {
      const api = "Admin/GetAllApplicationTypeByDepartment";
      ErrorlogData(deptID, error, api);
      console.error("Error fetching data:", error);
    }
  };
  // application list with department wise api start
  useEffect(() => {
    table_Data();
    departmentApi();
  }, [tabDepId]);
  const columns = [
    {
      name: "Application Category Name",
      selector: (row) => row.name,
      sortable: true,
      searchable: true,
      width: "60%",
    },

    {
      name: "Status",
      selector: (row) => row.status,
      sortable: true,
      searchable: true,
      width: "20%",
      cell: (row) => (
        <span>
          {row.status === 1 ? (
            <span className="badge rounded-pill bg-success">Active</span>
          ) : (
            <span className="badge rounded-pill bg-warning text-dark">
              Inactive
            </span>
          )}
        </span>
      ),
    },
    {
      name: "Action",
      width: "20%",
      cell: (row) => (
        <>
          {" "}
          <Link
            to=""
            className="me-2"
            onClick={() => handleUpdate(row.id, row.name)}
          >
            <i className="bi bi-pencil-square"></i>
          </Link>
        </>
      ),
    },
  ];

  // add application form start
  const [showForm, setShowForm] = useState(false);
  const handleFormClose = () => {
    setShowForm(false);
    setApplicationTypeForm({
      name: "",
      depID: "",
    });
    setformerr("");
    setAttachmentList([]);
  };

  const handleFormShow = () => setShowForm(true);
  const [applicationTypeForm, setApplicationTypeForm] = useState({
    name: "",
    depID: "",
  });

  const changeHandelForm = async (e) => {
    const { name, value } = e.target;
    if (value == 2 || value == 3) {
      setAttachmentListLoader(true);
      try {
        const DataReferred = {
          ApplicationTypeID: value == 2 ? "2" : "19",
          ApplicationSubTypeID: "0",
        };
        await axios
          .post(APIURL + "Master/GetAttachmentData", DataReferred)
          .then((res) => {
            if (res.data.responseCode === "200") {
              setAttachmentListLoader(false);
              setAttachmentList(res.data.responseData);
            } else {
              setAttachmentListLoader(false);
              setAttachmentList([]);
            }
          })
          .catch((err) => {
            const api = "Master/GetAttachmentData";
            ErrorlogData(DataReferred, err, api);
            console.log(err);
          });
      } catch (error) {
        console.log("error-----", error);
      }
    } else if (value == 4 || value == 5) {
      setAttachmentListLoader(false);
      setAttachmentList([]);
    }
    let newErrors = {};

    const spaceCheck = /\s{2,}/g;
    const specialCharsOLD = /[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;
    // const specialChars = /[^\w\s&,_%-]/;

    const specialChars = /[^\w\s&,-]/;
    if (name == "name" && specialChars.test(value)) {
      newErrors.name = "Special characters not allowed.";
    } else if (name == "name" && value.charAt(0) === " ") {
      newErrors.name = "First character cannot be a blank space.";
    } else if (name == "name" && spaceCheck.test(value)) {
      newErrors.name = "Multiple space not allow.";
    } else if (name == "name" && specialCharsOLD.test(value.charAt(0))) {
      newErrors.name = "First special character is not allowed.";
    } else {
      setApplicationTypeForm((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    }
    setformerr(newErrors);
  };
  // attachment check api start
  const handleChangeAttachment = (id) => {
    setAttachmentChecked((prevChecked) => {
      const isChecked = prevChecked.some((role) => role.checkedId === id);
      if (isChecked) {
        return prevChecked.filter((role) => role.checkedId !== id);
      } else {
        const selectedRole = attachmentList.find((role) => role.id === id);
        return selectedRole
          ? [
              ...prevChecked,
              { checkedId: selectedRole.id, Name: selectedRole.name },
            ]
          : prevChecked;
      }
    });
  };

  useEffect(() => {
    // Populate checked attachments by default on load
    const defaultChecked = attachmentList?.map((item) => ({
      checkedId: item.id,
      Name: item.name,
    }));
    setAttachmentChecked(defaultChecked);
  }, [attachmentList]);
  // attachment check api end

  const attachmentListData = attachmentChecked?.map((item) => ({
    Name: item.Name,
  }));

  const handleSubmit = async (e) => {
    e.preventDefault();
    const application_data = {
      Name: applicationTypeForm.name,
      DepartmentID: applicationTypeForm.depID,
    };
    const application_data_json = JSON.stringify(application_data);
    try {
      if (isValid()) {
        const application_responce = await fetch(
          APIURL + "Admin/AddApplicationType--",
          {
            method: "Post",
            headers: {
              "Content-Type": "application/json",
            },
            body: application_data_json,
          }
        );

        const data = await application_responce.json();
        setToastDisplayed(true);
        if (data.responseCode === "200") {
          toast.success(data.responseMessage, { autoClose: 2000 });
          try {
            const DataReferred = {
              ApplicationTypeID: data.responseData.applicationTypeID,
              attachmentModelReturns: attachmentListData,
            };
            await axios
              .post(
                APIURL + "Admin/AddAttachmentsByApplicationID",
                DataReferred
              )
              .then((res) => {
                console.log("res----------", res);
              })
              .catch((err) => {
                const api = "Admin/AddAttachmentsByApplicationID";
                ErrorlogData(DataReferred, err, api);
                console.log(err);
              });
          } catch (error) {
            console.log("error-----", error);
          }
          setTimeout(() => {
            setToastDisplayed(false);
            handleFormClose();
            table_Data();
            setSearchText("");
            setApplicationTypeForm({
              name: "",
              depID: "",
            });
          }, 2500);
        } else {
          toast.warning(data.responseMessage, { autoClose: 2000 });
          setTimeout(() => {
            table_Data();
            setToastDisplayed(false);
          }, 2500);
        }
      }
    } catch (error) {
      const api = "Admin/AddApplicationType";
      ErrorlogData(application_data_json, error, api);
      console.log("Fetching Error", error);
    }
  };
  // add application form end

  // validation start
  const isValid = () => {
    const newErrors = {};
    let valid = true;

    if (!applicationTypeForm.name) {
      newErrors.name = "Application category name is required.";
      valid = false;
    }
    if (!applicationTypeForm.depID) {
      newErrors.depID = "Please select department.";
      valid = false;
    }
    setformerr(newErrors);
    return valid;
  };
  // validation end

  // validation update start
  const isUpdateValid = () => {
    const newErrors = {};
    let valid = true;

    if (!updateData.name) {
      newErrors.name = "Application category name is required.";
      valid = false;
    }

    if (!updateData.departmentID) {
      newErrors.departmentID = "Please select department.";
      valid = false;
    }
    setupdateerr(newErrors);
    return valid;
  };
  // validation end

  const filteredData = tableData?.filter(
    (item) =>
      item.name?.toLowerCase().includes(searchText?.toLowerCase()) ||
      (item.id && item.id.toString().includes(searchText)) ||
      item.departmentName?.toLowerCase().includes(searchText?.toLowerCase()) ||
      (item.status == "1" ? "Active" : "Inactive")
        .toLowerCase()
        .includes(searchText?.toLowerCase())
  );

  return (
    <>
      <>
        {loader == true ? (
          <label className="outerloader2">
            {" "}
            <span className="loader"></span>
            <span className="loaderwait">Please Wait...</span>
          </label>
        ) : (
          <DataTable
            columns={columns}
            data={filteredData}
            persistTableHead={true}
            defaultSortFieldId={1}
            defaultSortAsc={true}
            pagination
            paginationRowsPerPageOptions={[10, 50, 100]}
            highlightOnHover
            dense
            striped
            fixedHeader
            subHeader
            subHeaderComponent={
              <>
                <div className="admintablesearch">
                  <div className="tablesearch_bx">
                    <input
                      type="text"
                      placeholder="Search"
                      value={searchText}
                      onChange={(e) => setSearchText(e.target.value)}
                    />
                  </div>
                  <div className="table-btn-bx">
                    {/* <CSVLink
                                data={filteredData}
                                filename={"movie_data.csv"}
                                className="hidden"
                                ref={csvLinkRef} 
                            >
                                Export to CSV
                            </CSVLink> */}
                    {/* <button onClick={handleExportExcel} disabled>Export to Excel</button> */}
                    <Button onClick={handleFormShow}>
                      Add Application Category
                    </Button>
                  </div>
                </div>
                <div className="application-tab w-100 mt-4">
                  <ul className="nav nav-pills mb-3">
                    <li className="nav-item">
                      <a
                        className={
                          tabDepId == 1 ? "nav-link active" : "nav-link"
                        }
                        onClick={(e) => handleClickTag(1)}
                      >
                        Exports
                      </a>
                    </li>
                    <li className="nav-item">
                      <a
                        className={
                          tabDepId == 2 ? "nav-link active" : "nav-link"
                        }
                        onClick={(e) => handleClickTag(2)}
                      >
                        Imports
                      </a>
                    </li>
                    <li className="nav-item">
                      <a
                        className={
                          tabDepId == 3 ? "nav-link active" : "nav-link"
                        }
                        onClick={(e) => handleClickTag(3)}
                      >
                        Foreign Investments
                      </a>
                    </li>
                    <li className="nav-item">
                      <a
                        className={
                          tabDepId == 4 ? "nav-link active" : "nav-link"
                        }
                        onClick={(e) => handleClickTag(4)}
                      >
                        Financial Surveillance
                      </a>
                    </li>
                  </ul>
                </div>
              </>
            }
          />
        )}
      </>

      {/* Application Form modal */}
      <Modal show={showForm} onHide={handleFormClose} backdrop="static">
        <div className="application-box editmodal-change">
          <div className="login_inner">
            <div className="login_form ">
              <h5>
                {" "}
                <Modal.Header closeButton className="p-0">
                  <Modal.Title>Add Application Category</Modal.Title>
                </Modal.Header>
              </h5>
            </div>
            <div className="login_form_panel">
              <Modal.Body className="p-0">
                <div className="form-bx mb-4">
                  <p className="form-label">
                    Application Category Name{" "}
                    <span style={{ color: "#ff0000" }}>*</span>
                  </p>
                  <label>
                    <input
                      type="text"
                      name="name"
                      className="fomcontrol text-capitalize"
                      value={applicationTypeForm?.name}
                      placeholder="Application Category Name"
                      onChange={(e) => {
                        changeHandelForm(e);
                      }}
                      onKeyDown={(e) => {
                        if (e.key === "_") {
                          e.preventDefault();
                        }
                      }}
                      required
                    />
                    <span className="sspan"></span>
                  </label>
                  {formerr?.name ? (
                    <span className="errormsg">{formerr?.name}</span>
                  ) : (
                    ""
                  )}
                </div>
                <div className="form-bx mb-4">
                  <p className="form-label">
                    Select Department{" "}
                    <span style={{ color: "#ff0000" }}>*</span>
                  </p>
                  <label>
                    <select
                      name="depID"
                      className=""
                      aria-label="Large select example"
                      onChange={(e) => {
                        changeHandelForm(e);
                      }}
                    >
                      <option value="" selected>
                        Select Department
                      </option>
                      {departmentData.map((item) => {
                        return (
                          <option value={item.id}>{item.departmentName}</option>
                        );
                      })}
                    </select>
                    <span className="sspan"></span>
                  </label>
                  {formerr?.depID ? (
                    <span className="errormsg">{formerr?.depID}</span>
                  ) : (
                    ""
                  )}
                </div>

                {attachmentListLoader == true ? (
                  <label className="outerloader2">
                    {" "}
                    <span className="loader"></span>
                  </label>
                ) : (
                  <>
                    {attachmentList.length > 0 ? (
                      <div className="form-bx mb-4">
                        <p className="form-label">Attachment</p>
                        <div className="checkbox-Box">
                          {attachmentList.length
                            ? attachmentList?.map((item, index) => {
                                return (
                                  <div
                                    className="deptInner-box d-flex mb-2"
                                    key={index}
                                  >
                                    <input
                                      type="checkbox"
                                      id={`role_${item.id}`}
                                      name={item.name}
                                      value={item.id}
                                      // checked={attachmentChecked?.some(
                                      //     (selectedRole) => selectedRole.roleID === item.id
                                      // )}
                                      checked={attachmentChecked.some(
                                        (selectedRole) =>
                                          selectedRole.checkedId === item.id
                                      )}
                                      onChange={() =>
                                        handleChangeAttachment(item.id)
                                      }
                                    />
                                    <span htmlFor={`role_${item.id}`}>
                                      {item.name}
                                    </span>
                                  </div>
                                );
                              })
                            : " "}

                          {/* <div className="deptInner-box d-flex mb-2">
                                            <input type="checkbox" id="import" name="import" value="import" />
                                            <span for="import">Import</span>
                                        </div> */}
                        </div>
                      </div>
                    ) : (
                      " "
                    )}
                  </>
                )}
              </Modal.Body>
              <Modal.Footer className="p-0">
                <Button
                  variant="primary"
                  onClick={handleSubmit}
                  disabled={toastDisplayed ? true : false}
                >
                  Submit
                </Button>
              </Modal.Footer>
            </div>
          </div>
        </div>
      </Modal>

      {/* Application Form update modal */}
      <Modal show={showUpdateModal} onHide={UpdateModalClose} backdrop="static">
        <div className="application-box editmodal-change">
          <div className="login_inner">
            <div className="login_form ">
              <h5>
                <Modal.Header closeButton className="p-0">
                  <Modal.Title>Update Application Category</Modal.Title>
                </Modal.Header>
              </h5>
            </div>
            <div className="login_form_panel">
              <Modal.Body className="p-0">
                <div className="form-bx mb-4">
                  <p className="form-label">
                    Application Category Name{" "}
                    <span style={{ color: "#ff0000" }}>*</span>
                  </p>
                  <label>
                    <input
                      type="text"
                      name="name"
                      value={updateData?.name}
                      className="fomcontrol text-capitalize"
                      placeholder="Application Category Name"
                      onChange={(e) => {
                        changeUpdateForm(e);
                      }}
                      onKeyDown={(e) => {
                        if (e.key === "_") {
                          e.preventDefault();
                        }
                      }}
                      required
                    />
                    <span className="sspan"></span>
                  </label>
                  {updateerr?.name ? (
                    <span className="errormsg">{updateerr?.name}</span>
                  ) : (
                    ""
                  )}
                </div>
                <div className="form-bx mb-4">
                  <p className="form-label">
                    Select Department{" "}
                    <span style={{ color: "#ff0000" }}>*</span>
                  </p>
                  <label>
                    <select
                      name="departmentID"
                      className=""
                      aria-label="Large select example"
                      onChange={(e) => {
                        changeUpdateForm(e);
                      }}
                      required
                      value={updateData.departmentID}
                    >
                      <option value="" selected>
                        Select Department
                      </option>
                      {departmentData.map((item) => {
                        return (
                          <option
                            value={item.id}
                            selected={
                              item.id === updateData.departmentID ? true : false
                            }
                          >
                            {item.departmentName}
                          </option>
                        );
                      })}
                    </select>
                    <span className="sspan"></span>
                  </label>
                  {updateerr?.departmentID ? (
                    <span className="errormsg">{updateerr?.departmentID}</span>
                  ) : (
                    ""
                  )}
                </div>
                <div className="form-bx mb-4">
                  <p className="form-label">Select Status</p>
                  <label>
                    <select
                      name="status"
                      className=""
                      aria-label="Large select example"
                      onChange={(e) => {
                        changeUpdateForm(e);
                      }}
                      value={updateData.status}
                    >
                      <option value="0">Inactive</option>
                      <option value="1">Active</option>
                    </select>
                    <span className="sspan"></span>
                  </label>
                  {/* {errors === true && !updateData.name ? <small className="errormsg">Name is Required</small> : ''} */}
                </div>
              </Modal.Body>
              <Modal.Footer className="p-0">
                <Button
                  variant="primary"
                  onClick={handleUpdateData}
                  disabled={toastDisplayed ? true : false}
                >
                  Update
                </Button>
              </Modal.Footer>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default ApplicationTypeTable;
